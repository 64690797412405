import React, { useContext, useState, useRef } from "react";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { ExpandedSectionContext, TermsAgreementContext } from '../../Store'
import styles from "./termsagreement.module.css";
import FontAwesome from "react-fontawesome";
import { Checkbox, FormControlLabel } from '@material-ui/core';

const TermsAgreement = ({Name, termsText, termsCheckboxLabel})=>{
    const [expandedSection, setExpandedSection] = useContext(
        ExpandedSectionContext
    );
    const [termsAgreementChecked, setTermsAgreementChecked] = useContext(TermsAgreementContext);
    const formRef = useRef(null);

    const handleOpen = () => {
        expandedSection === Name
          ? setExpandedSection("")
          : setExpandedSection(Name);
    };
    const onEntered = () => {
        formRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      };
    const checkBoxClicked = () => {
        setTermsAgreementChecked(!termsAgreementChecked);
    }
    return (
        <div>
            <Button
            innerRef={formRef}
            className={styles["CollapseBtn"]}
            onClick={handleOpen}
            style={
                termsAgreementChecked
                  ? {
                      color: "white",
                      ":focus": {
                        backgroundColor: "#02BD43",
                      },
                      backgroundColor: "#02BD43",
                      marginBottom: "1rem",
                      width: "100%",
                    }
                  : {
                      ":focus": {
                        backgroundColor: "#fafafa",
                      },
                      marginBottom: "1rem",
                      width: "100%",
                    }
              }
            >
                <p>TERMS AGREEMENT</p>
                <FontAwesome
                    className="super-crazy-colors"
                    name="angle-up"
                    rotate={expandedSection === Name ? null : 180}
                    size="lg"
                    style={{
                    marginTop: "5px",
                    textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
                    }}
                />
            </Button>
            <Collapse
                onEntered={onEntered}
                className={styles["Collapse"]}
                isOpen={expandedSection === Name}
            >
                <Card>
                    <CardBody>
                        <h6
                        className={styles["termsText"]}
                        >
                            {termsText.split('{{').map(str=><p>{str}</p>)}
                        </h6>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={termsAgreementChecked}
                                onChange={checkBoxClicked}
                                name="termsCheckbox"
                                color="primary"
                            />
                            }
                            label={termsCheckboxLabel}
                        />
                    </CardBody>
                </Card>
            </Collapse>
        </div>
    )
}
//
export default TermsAgreement;