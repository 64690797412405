import React, { useContext, useState } from "react";
import {
  ImageUploadContext,
  CommentsContext,
  LoadingContext,
} from "../../Store";
import styles from "./commentssection.module.css";
import axios from "axios";

const ImageUploader = React.memo(({ title, name }) => {
  const [errors, setErrors] = useState(false);
  const [tooMany, setTooMany] = useState(false);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [comments, setComments] = useContext(CommentsContext);
  const [files, setFiles] = useContext(ImageUploadContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setComments((state) => ({ ...state, [name]: value }));
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };
  const uploadImage = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    if (files[name] && files[name].length > 8) {
      setTooMany(true);
      return false;
    }
    if (!file) {
      setErrors(true);
      return false;
    }
    if (file.size >= 10000000) {
      setErrors(true);
      return false;
    }
    const formData = new FormData();
    await formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const uploadResponse = await axios.post(
      `uploadImage`,
      formData,
      config
    );
    // const imageURL = uploadResponse.data.secure_url;
    const {
      fileId, 
      filename
    } = uploadResponse.data.file;

    if (files[name]) {
      setFiles((state) => ({
        ...state,
        // [name]: [...state[name], imageURL],
        [name]: [...state[name], 
        {
          filename, 
          fileId
        }]
      }));
      setIsLoading(false);
    } else {
      setFiles((state) => ({
        ...state,
        // [name]: [imageURL],
        [name]: [{
          filename, 
          fileId
        }]
      }));
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p>Please include comments in notes</p>
      <hr className={styles["HR"]} />
      <div className={styles["UploadForm"]}>
        <div className={styles["UploadWrapper"]}>
          <h5>Upload photos of issues found in the {title}</h5>
          {errors ? (
            <p style={{ color: "blue" }}>
              *File size needs to be less than 10MB*
            </p>
          ) : null}
          {tooMany ? (
            <p style={{ color: "blue" }}>
              *You've hit the maximum number of files*
            </p>
          ) : null}
          <section className={styles["Container"]}>
            <button className={styles["UploadBtn"]} onClick={handleClick}>
              Click here to upload a file!
            </button>
            <input
              onChange={uploadImage}
              accept="image/*"
              type="file"
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />
          </section>
          <div>
            {files[name]
              ? files[name].map((object, index) => {
                  return (
                    // <img
                    //   alt={object.filename}
                    //   style={{ maxHeight: "100px" }}
                    //   className={styles["RenderedImage"]}
                    //   key={index}
                    //   src={object}
                    // />
                    <p>{object.filename.split('_').splice(1).join('')}</p>
                  );
                })
              : null}
          </div>
          <p>
            Please take a picture of any issues that you find and upload them
            here. NOTE: it is only necessary to upload pictures of problems that
            you find.
          </p>
        </div>
        <div className={styles["CommentWrapper"]}>
          <h5>Notes of the {title}</h5>
          <textarea
            name={name}
            value={comments[name]}
            onChange={handleChange}
            className={styles["Textarea"]}
          />
        </div>
      </div>
    </div>
  );
});
export default ImageUploader;
