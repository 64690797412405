import React, {useState, useEffect} from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles({
    forgot: {
        textAlign: 'center', 
    }, 
    body: {
        backgroundSize: 'cover',
        backgroundImage: 'linear-gradient(#f3f3a5, #f9f9d2)',
        height: '100vh',
    }
});

const AdminLogin = () => {
    const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem('userToken') ? true : false);
    const [email, setEmail] = useState('');

    const classes = useStyles();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const loginCreds = {
            email: data.get('email'),
            password: data.get('password'),
        }
        console.log(loginCreds);

        try{
            const serverResponse = await axios.post('login', loginCreds, {
                validateStatus: function(status){
                  return status
                }
            });
            if(serverResponse.status == 200){
                sessionStorage.setItem('userToken', serverResponse.data.token);
                setLoggedIn(true);
                return;
            }
            console.log('server response', serverResponse);
            if(serverResponse.data.error == 'Wrong Password'){
                toast.error('Incorrect Password', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else if(serverResponse.data.error == 'User not found'){
                toast.error('No user associated with this email', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else if(serverResponse.data.error == 'Invalid User Role - Not Admin User'){
                toast.error('Invalid User Role', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }catch(error) {
            console.error('could not login', error);
        }
    }

    const onFocus = (event) => {
        if(event.target.autocomplete){
            event.target.autocomplete = 'off';
        }
    }

    const forgotPassword = async (event) => {
        const data = new FormData(event.target.form);
        const emailFieldVal = data.get('email');
        await axios({
            method: 'post', 
            url: "https://www.apartmentmii.com/resetpassword",
            data: {
                email: emailFieldVal
            }
        });
        toast.success('Password reset, you will receive an email shortly', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <ToastContainer />
            {loggedIn && <Redirect to="/admin" />}
            <div className={classes.body}>
                <Container component="main" maxWidth="xs"
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100vh',
                }}
                >
                <CssBaseline />
                <Box
                    sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: '25px 50px',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    borderRadius: '5%',
                    marginTop: '0px !important'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    Admin Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onFocus={onFocus}
                        autoComplete="new-password"
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Grid container className={classes.forgot}>
                        <Grid item xs>
                        <Button variant="outlined" onClick={forgotPassword}>
                            Forgot password?
                        </Button>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    </Box>
                </Box>
                </Container>
            </div>
        </>
    )
}

export default AdminLogin;