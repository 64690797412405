import React, { useContext, useRef } from "react";
import {
  ExpandedSectionContext,
  StripeElementContext,
  StripePaymentContext,
} from "../../Store";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import FontAwesome from "react-fontawesome";
import styles from "./payment.module.css";

const Payment = React.memo(({ Name, children }) => {
  const formRef = useRef(null);
  const [expandedSection, setExpandedSection] = useContext(
    ExpandedSectionContext
  );

  const handleOpen = () => {
    expandedSection === Name
      ? setExpandedSection("")
      : setExpandedSection(Name);
  };
  const onEntered = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  return (
    <div>
      <Button
        innerRef={formRef}
        className={styles["CollapseBtn"]}
        onClick={handleOpen}
      >
        <p>PAYMENT</p>
        <FontAwesome
          className="super-crazy-colors"
          name="angle-up"
          rotate={expandedSection === Name ? null : 180}
          size="lg"
          style={{
            marginTop: "5px",
            textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
          }}
        />
      </Button>
      <Collapse
        onEntered={onEntered}
        className={styles["Collapse"]}
        isOpen={expandedSection === Name}
      >
        <Card>
          <CardBody>{children}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
});

export default Payment;
