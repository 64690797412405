import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { makeStyles } from '@material-ui/styles';
import PdfPage from '../../pdf/Pdf';
import axios from 'axios';
import Loader from "react-loader-spinner";
import styles from './table.module.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


const useStyles = makeStyles({
    box: {
        paddingLeft: 'clamp(300px, 20%, 20%)', 
        width: '90%', 
        maxHeight: '80%', 
        overflow: 'scroll', 
        marginTop: '150px', 
        height: '100%'
    }, 
    boxMobile: {
        width: '100%', 
        maxHeight: '80%', 
        overflow: 'scroll', 
        marginTop: '150px', 
        height: '100%'
    }, 
    tableHeader: {
      flex: '0 !important', 
      fontSize: '24px !important', 
      marginLeft: '25px !important'
    }, 
    tableHeaderMobile: {
      flex: '1 !important', 
      fontSize: '24px !important', 
      marginLeft: '25px !important', 
      marginBottom: '50px !important'
    }, 
    pdf: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }, 
    pdfPage: {
      marginTop: '500px'
    }
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'address',
    numeric: false,
    disablePadding: true,
    label: 'Address',
  },
  {
    id: 'resident',
    numeric: true,
    disablePadding: false,
    label: 'Resident',
  },
  {
    id: 'moveInForm',
    numeric: true,
    disablePadding: false,
    label: 'Move In Form',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {

  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        height: '100px'
      }}
    >
        <Typography
          className={props.mobile ? classes.tableHeaderMobile : classes.tableHeader}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Residents
        </Typography>
        {props.children}
    </Toolbar>
  );
};



export default function EnhancedTable({mobile, data, children, apartment, retrieveResidents}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showPdf, setShowPdf] = React.useState(false);
  const [pdfData, setPdfData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false)
  const rows = data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadPdf = async function(event, data) {
    setIsLoading(true);
    const resident = event.target.previousElementSibling.innerHTML;
    const response = await axios({
      method: "post",
      url: "https://www.apartmentmii.com/api/getresident",
      data: {
        apartment: apartment, 
        name: resident
      }
    });
    console.log('response', response.data[0]);
    setPdfData(response.data[0])
    setShowPdf(true);
  }

  const finishedLoading = () => {
    setIsLoading(false);
  }

  const retrievingImages = () => {
    NotificationManager.info('Downloading PDF images, please wait');
  }

  const resetTable = () => {
    window.location.reload();
  }

  const classes = useStyles();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box className={mobile ? classes.boxMobile : classes.box}>
      <NotificationContainer/>
      {isLoading ? (
        <Loader
          className={styles["Loader"]}
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
        />
      ) : null}
      <Paper sx={{ width: '100%', mb: 2, position: 'relative' }}>
        <EnhancedTableToolbar
          mobile={mobile}
        >
            {children}
        </EnhancedTableToolbar>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            >
            </EnhancedTableHead>
            <TableBody>

              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell align="center">{row.address}</TableCell>
                      <TableCell align="center">{row.tenants}</TableCell>
                      <TableCell onClick={(function(e){ downloadPdf(e, data)})} align="center" className={classes.pdf}>{row.moveInForm}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{'& .MuiTablePagination-selectLabel': {marginBottom: '0px !important'}, '& .MuiTablePagination-displayedRows': {marginBottom: '0px !important'}}}
        />
      </Paper>
      {showPdf && 
      <PdfPage 
        className={classes.pdfPage}
        starRatings={pdfData.starRatings}
        keys={pdfData.keys}
        formAnswers={pdfData.formAnswers}
        areas={pdfData.areas}
        signature={pdfData.signature}
        surveyQs={pdfData.surveyQs}
        address={pdfData.address}
        name={pdfData.name}
        date={pdfData.date}
        apartment={pdfData.apartment}
        images={pdfData.images}
        finishedLoading={finishedLoading}
        retrievingImages={retrievingImages}
        resetTable={resetTable}
      />}
    </Box>
  );
}
