import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import VerticalTabs from './verticalTabs';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import EnhancedTable from './Table';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Redirect } from 'react-router-dom';


const useStyles = makeStyles({
    dashboardBody: {
        height: '100vh', 
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        textAlign: 'center', 
        position: 'absolute', 
        top: '0'
    }, 
    tabs: {
        position: 'absolute', 
        top: '0'
    },
    logo: {
        width: '15%', 
        minWidth: '250px',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        marginTop: '50px'
    }, 
    search: {
        marginTop: '0px !important',
        marginBottom: '0px !important',
        position: 'absolute !important', 
        top: '20px', 
        right: '100px',
        cursor: 'pointer',
        '& .MuiInput-root': {
            fontSize: '24px'
        }, 
        '& .MuiSvgIcon-root': {
            fontSize: '24px'
        }, 
        '& .MuiInputLabel-root': {
            fontSize: '18px', 
            left: '20%'
        }, 
        '& .css-i4bv87-MuiSvgIcon-root': {
            position: 'absolute', 
            right: '-50px', 
            cursor: 'pointer', 
            fontSize: '24px', 
            color: 'black'
        }
    }, 
    searchMobile: {
        marginTop: '0px !important',
        marginBottom: '0px !important',
        position: 'absolute !important', 
        top: '40px', 
        right: '40px',
        cursor: 'pointer',
        '& .MuiInput-root': {
            fontSize: '24px'
        }, 
        '& .MuiSvgIcon-root': {
            fontSize: '24px'
        }, 
        '& .MuiInputLabel-root': {
            fontSize: '18px', 
            left: '20%'
        }, 
        '& .css-i4bv87-MuiSvgIcon-root': {
            position: 'absolute', 
            right: '-50px', 
            cursor: 'pointer', 
            fontSize: '24px', 
            color: 'black'
        }
    },
    paper: {
        marginLeft: 'clamp(300px, 20%, 20%)', 
        marginTop: '50px', 
        width: '70%', 
        height: '80%'
    }, 
    paperMobile: {
        marginTop: '50px', 
        width: '100%%', 
        height: '80%'
    },
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
});

const DashboardPage = () => {
    const [logo, setLogo] = useState("");
    const [backgroundImage, setBackgroundImage] = useState("");
    const [propertyManagerPassword, setPropertyManagerPassword] = useState('');
    const [apartmentName, setApartmentName] = useState('');
    const [apartmentTitle, setApartmentTitle] = useState("");
    const [searchData, setSearchData] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [searching, setSearching] = useState(false);
    const [click, setClick] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [data, setData] = useState([]);
    const [loggedIn, setLoggedIn] = useState(true);

    const handleClick = () => setClick(!click);

    const showMobile = () => {
        if(window.innerWidth <= 960){
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    const validateUser = async () => {
        try{    
            const token = sessionStorage.getItem('userToken');
            if(!token) throw 'No user token';
            const validationResponse = await axios({
                method: "get",
                url: `https://www.apartmentmii.com/users?secret_token=${token}`,
            });
        }catch (error){
            console.log('could not validate user', error);
            setLoggedIn(false);
        }
    }

    const classes = useStyles();

    if(!apartmentName){
      setApartmentName(window.location.pathname.split('/')[1].split('/')[0]);
    }


    const getData = async () => {
      axios
      .get(`${window.location.origin}/api/${apartmentName}`)
      .then((res) => {
        const response = res.data[0];
        if(typeof response == 'object'){
            setLogo(response.logo);
            setBackgroundImage(response.backgroundImage);
            setApartmentTitle(response.apartmentTitle);
            setPropertyManagerPassword(response.PropertyManagerPassword);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }

    const retrieveResidents = async (reset) => {
        const response = await axios({
            method: "post",
            url: "https://www.apartmentmii.com/api/residents",
            data: {
                apartment: apartmentName, 
            }
        });
        if(response.data.length){
            const tableData = [];
            response.data.forEach((tenant, index) => {
                const dataObj = {
                    address: tenant.address, 
                    tenants: tenant.name, 
                    moveInForm: `${tenant.name} Move In Form.pdf`,
                    key: index
                }
                tableData.push(dataObj);
            });
            if(reset){
                setData([...tableData])
            }else{
                setData([...data, ...tableData]);
            }
        }
    }

    const onSearchChange = (event) => {
        setSearchVal(event.target.value);
        if(!searchVal.length){
            setSearchData([]);
            setSearching(false);
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            onSearch();
        }
    }

    const onSearch = () => {
        setSearching(true);
        setSearchData(data.filter((items) => {
            return items.address.toLowerCase().includes(searchVal.toLowerCase()) || items.tenants.toLowerCase().includes(searchVal.toLowerCase());
        }));
    }

    const clearSearch = () => {
        setSearching(false);
        setSearchVal('');
    }

    window.addEventListener('resize', showMobile);

    useEffect(() => {
      getData();
      showMobile();
      retrieveResidents();
      validateUser();
    }, []);
    return (
        <div className={classes.dashboardBody}>
            {!loggedIn && <Redirect to={`/${apartmentName}/dashboard/login`} />}
            {mobile && !click ? 
            <MenuIcon className={classes.menu} onClick={handleClick} /> :
            <VerticalTabs className={classes.tabs}
                apartmentName={apartmentName}
                initialTab={0}
            >
                {mobile && click ? <CloseIcon className={classes.close} onClick={handleClick} /> : null}
                <img className={classes.logo} src={logo}></img>    
            </VerticalTabs> 
            }
            
            
            <EnhancedTable 
                mobile={mobile}
                apartment={apartmentName}
                data={searching && searchVal.length ? searchData : data}
                sx={{position: 'relative', marginTop: '100px'}}
                retrieveResidents={retrieveResidents}
            >
                <TextField
                    id="input-with-icon-textfield"
                    label="Search address or resident..."
                    onChange={onSearchChange}
                    value={searchVal}
                    className={mobile ? classes.searchMobile : classes.search}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon
                                sx={{position: 'absolute !important', right: '0', fontSize: 'large', cursor: 'pointer'}}
                                onClick={onSearch}
                            />
                            {searchVal.length ? (<HighlightOffIcon className={classes.clearIcon} onClick={clearSearch} />) : null}

                        </InputAdornment>
                        
                    ),
                    }}
                    variant="standard"
                />
            </EnhancedTable>         
        </div>
    )
};

export default DashboardPage;
