
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import React, {useEffect, useState} from "react";
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import randomstring from 'randomstring';


const useStyles = makeStyles({
    dashboardBody: {
        height: '100vh', 
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        textAlign: 'center', 
        position: 'absolute', 
        top: '0',
        overflowY: 'hidden'
    }, 
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer', 
        zIndex: '5'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    },
    root: {
        backgroundSize: 'cover',
        height: '100vh',
        '& .MuiPaper-root': {
            width: '400px !important', 
            alignItems: 'center', 
            borderRadius: '20px', 
            height: '300px', 
            justifyContent: 'center'
        }
    }, 
    selectLabel: {
        marginTop: '25px', 
        marginBottom: '0px', 
        textAlign: 'left'
    }, 
    select: {
        marginTop: '0px !important', 
        marginBottom: '25px'
    }, 
    textField: {
        marginBottom: '15px', 
        display: 'block', 
        '& .MuiInputBase-root': {
            width: '250px !important'
        }
    }, 
    modal: {
        height: '100%',
        width: '500px'
    }, 
    cancel: {
        marginLeft: '15px'
    }, 
    submit: {
        marginLeft: '25px'
    }
});

const Kitchen = ({closeModal}) => {
    const [category, setCategory] = useState('');

    const classes = useStyles();

    const onClose = () => {
        closeModal(true);
    }

    const categoryCreated = () => {
        closeModal(true, category);
    }
  
    const handleInputChange = (e) => {
        const { value } = e.target;
        setCategory(value);
        console.log('category', category);
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        categoryCreated();
    };


    return (
        <div className={classes.body}>
            <Dialog
                className={classes.root}
                fullWidth
                maxWidth="md"
                open={true}
            >
                <Typography
                    variant="h5"
                    id="tableTitle"
                    component="div"
                >
                    Add Kitchen Category
                </Typography>
                    <form>
                        <TextField 
                            multiline
                            className={classes.textField}
                            id="category-input"
                            label="Category"
                            type="text"
                            name="category"
                            value={category}
                            onChange={handleInputChange}
                        />
                        <Button variant="contained" color="primary" className={classes.submit} onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button onClick={onClose} className={classes.cancel} variant="outlined" color="primary" type="button">
                            Cancel
                        </Button>
                    </form>
            </Dialog>
        </div>
    )
}

export default Kitchen;