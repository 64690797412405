import React, { useContext, useRef } from "react";
import { ContactInfoContext, ExpandedSectionContext } from "../../Store";
import { FormGroup, Input, Label, CustomInput } from "reactstrap";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import FontAwesome from "react-fontawesome";
import InputMask from "react-input-mask";
import styles from "./contactform.module.css";

const ContactForm = React.memo(({ Name }) => {
  const formRef = useRef(null);
  const [expandedSection, setExpandedSection] = useContext(
    ExpandedSectionContext
  );
  const [contactInfo, setContactInfo] = useContext(ContactInfoContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOpen = () => {
    expandedSection === Name
      ? setExpandedSection("")
      : setExpandedSection(Name);
  };
  const onEntered = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const answeredQuestions = Object.keys(contactInfo);
  const requiredFieldsFilled =
    answeredQuestions &&
    answeredQuestions.length &&
    answeredQuestions.includes("fname") &&
    answeredQuestions.includes("lname") &&
    answeredQuestions.includes("email") &&
    answeredQuestions.includes("phone") &&
    answeredQuestions.includes("community") &&
    answeredQuestions.includes("address") &&
    answeredQuestions.includes("city") &&
    answeredQuestions.includes("state") &&
    answeredQuestions.includes("zip") &&
    answeredQuestions.includes("pets")
      ? true
      : false;
  return (
    <div>
      <Button
        innerRef={formRef}
        className={styles["CollapseBtn"]}
        onClick={handleOpen}
        style={
          answeredQuestions.length && requiredFieldsFilled
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#02BD43",
                },
                backgroundColor: "#02BD43",
                marginBottom: "1rem",
                width: "100%",
              }
            : answeredQuestions.length && !requiredFieldsFilled
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#bd0202",
                },
                backgroundColor: "#bd0202",
                marginBottom: "1rem",
                width: "100%",
              }
            : {
                ":focus": {
                  backgroundColor: "#fafafa",
                },
                marginBottom: "1rem",
                width: "100%",
              }
        }
      >
        <p>RESIDENT INFORMATION</p>
        <FontAwesome
          className="super-crazy-colors"
          name="angle-up"
          rotate={expandedSection === Name ? null : 180}
          size="lg"
          style={{
            marginTop: "5px",
            textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
          }}
        />
      </Button>
      <Collapse
        onEntered={onEntered}
        className={styles["Collapse"]}
        isOpen={expandedSection === Name}
      >
        <Card>
          <CardBody>
            <div id="contact-form">
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="exampleEmail">
                  First Name
                </Label>
                <Input
                  value={contactInfo.fname}
                  onChange={handleChange}
                  type="text"
                  placeholder="First Name"
                  name="fname"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  Last Name
                </Label>
                <Input
                  value={contactInfo.lname}
                  onChange={handleChange}
                  type="text"
                  placeholder="Last Name"
                  name="lname"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  Email
                </Label>
                <Input
                  value={contactInfo.email}
                  onChange={handleChange}
                  type="text"
                  placeholder="Email"
                  name="email"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label for="examplePassword">Roommate's Email #1</Label>
                <Input
                  value={contactInfo.roommateone}
                  onChange={handleChange}
                  type="text"
                  placeholder="Roommate's Email #1"
                  name="roommateone"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label for="examplePassword">Roommate's Email #2</Label>
                <Input
                  value={contactInfo.roommatetwo}
                  onChange={handleChange}
                  type="text"
                  placeholder="Roommate's Email #2"
                  name="roommatetwo"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label for="examplePassword">Roommate's Email #3</Label>
                <Input
                  value={contactInfo.roommatethree}
                  onChange={handleChange}
                  type="text"
                  placeholder="Roommate's Email #3"
                  name="roommatethree"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  Phone
                </Label>
                <Input
                  value={contactInfo.phone}
                  onChange={handleChange}
                  type="tel"
                  placeholder="Phone"
                  name="phone"
                  tag={InputMask}
                  mask="(999) 999-9999"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  Apartment Community Name
                </Label>
                <Input
                  value={contactInfo.community}
                  onChange={handleChange}
                  type="text"
                  placeholder="Apartment Community Name"
                  name="community"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  Address
                </Label>
                <Input
                  value={contactInfo.address}
                  onChange={handleChange}
                  type="address"
                  placeholder="Address"
                  name="address"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label for="examplePassword">Unit #</Label>
                <Input
                  value={contactInfo.unit}
                  onChange={handleChange}
                  type="text"
                  placeholder="Unit #"
                  name="unit"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  City
                </Label>
                <Input
                  value={contactInfo.city}
                  onChange={handleChange}
                  type="city"
                  placeholder="City"
                  name="city"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="exampleSelect">
                  State
                </Label>
                <Input
                  value={contactInfo.state}
                  onChange={handleChange}
                  type="select"
                  placeholder="State"
                  name="state"
                >
                  <option value=""></option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Input>
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  Zip Code
                </Label>
                <Input
                  pattern="\d*"
                  value={contactInfo.zip}
                  onChange={handleChange}
                  type="number"
                  placeholder="Zip Code"
                  name="zip"
                />
              </FormGroup>
              <FormGroup className={styles["ContactFormGroup"]}>
                <Label className={styles["Required"]} for="examplePassword">
                  Pets
                </Label>
                <CustomInput
                  checked={contactInfo.pets === "Yes"}
                  onChange={handleChange}
                  type="radio"
                  id={`PetsNo`}
                  name={"pets"}
                  value="Yes"
                  label="Yes"
                />
                <CustomInput
                  checked={contactInfo.pets === "No"}
                  onChange={handleChange}
                  type="radio"
                  id={`PetsYes`}
                  name={"pets"}
                  value="No"
                  label="No"
                />
              </FormGroup>
            </div>
            {!answeredQuestions.length ? (
              ""
            ) : (
              <Button
                onClick={(e) => e.preventDefault()}
                style={
                  answeredQuestions.length && !requiredFieldsFilled
                    ? {
                        backgroundColor: "#bd0202",
                        color: "white",
                        pointerEvents: "none",
                      }
                    : {
                        backgroundColor: "#02BD43",
                        color: "white",
                        pointerEvents: "none",
                      }
                }
              >
                {answeredQuestions.length && !requiredFieldsFilled
                  ? "Incomplete"
                  : "Complete"}
              </Button>
            )}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
});

export default ContactForm;
