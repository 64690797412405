import React, {useContext, useEffect, useState} from "react";
import {
    Form,
    Input,
    // Button
} from "antd";
import axios from "axios";
import {NavLink} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import styles from "./dashboard.module.css";
import DashboardPage from './DashboardPage';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles({
  form: {
    padding: '25px 75px',
    paddingBottom: '30px',
    backgroundColor: 'white',
    borderRadius: '10px',
    textAlign: 'center'
  },
  title: {
    fontSize: '25px'
  },
  header: {
    marginBottom: '22px',
    textAlign: 'center', 
    fontSize: '22px'
  }, 
  input: {
    '& input': {
      paddingLeft: '8px', 
      padding: '4px', 
      borderRadius: '3px',
      border: '1px solid gray',
      margin: '10px 6px 15px 0px'
    }
  }, 
  submit: {
    background: '#d0e5f2',
    border: '1px solid gray',
    borderRadius: '4px', 
    padding: '5px 10px',
    '&:hover': {
      background: '#b4d7ed'
    },
  }, 
  btnText: {
    color: 'black', 
    marginBotton: '2px', 
    marginTop: '15px', 
    fontSize: '22px',
    textDecoration: 'underline', 
    color: '#1976d2 !important'
  }, 
  forgot: {
    textAlign: 'center', 
  }, 
  dashboard: {
    textAlign: 'center', 
  }, 
  homeLink: {
    position: 'absolute', 
    top: '25px', 
    right: '50px', 
    padding: '15px', 
    borderRadius: '50%',
    backgroundColor: 'white !important', 
    boxShadow: '0px 0px 10px gray'
  }, 
  homeIcon: {
    fontSize: '44px !important', 
    color: 'black !important'
  }
});
// login page for property mananger dashboard
const Dashboard = () => {
    const [passwordCorrect, setPasswordCorrect] = React.useState(false);
    const [password, setPassword] = useState("");
    const [logo, setLogo] = useState("");
    const [backgroundImage, setBackgroundImage] = useState("");
    const [propertyManagerPassword, setPropertyManagerPassword] = useState('');
    const [apartmentName, setApartmentName] = useState('');
    const [apartmentTitle, setApartmentTitle] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
      const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };
      const classes = useStyles();

    if(!apartmentName){
      setApartmentName(window.location.pathname.split('/')[1].split('/')[0]);
    }

    const getData = async () => {
      axios
      .get(`https://www.apartmentmii.com/api/${apartmentName}`)
      // .get(`${window.location.origin}/api/${apartmentName}`)
      .then((res) => {
        const response = res.data[0];
        if(typeof response == 'object'){
          setLogo(response.logo);
          setBackgroundImage(response.backgroundImage);
          setApartmentTitle(response.apartmentTitle);
          setPropertyManagerPassword(response.PropertyManagerPassword);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    const checkPassword = (e) => {
        e.preventDefault();
        if (password === propertyManagerPassword) {
            setPasswordCorrect(true);
        } else {
            setPasswordCorrect(false);
        }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const loginCreds = {
          email: data.get('email'),
          password: data.get('password'),
          apartment: apartmentName
      }

      const serverResponse = await axios.post(`login`, loginCreds, {
        validateStatus: function(status){
          return status
        }
      });
      if(serverResponse.status == 200){
        sessionStorage.setItem('userToken', serverResponse.data.token);
        setLoggedIn(true);
        return;
      }

      if(serverResponse.data.error == 'Wrong Password'){
        toast.error('Incorrect Password', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }else if(serverResponse.data.error == 'User not found'){
        toast.error('No user associated with this email', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    const onFocus = (event) => {
        if(event.target.autocomplete){
            event.target.autocomplete = 'off';
        }
    }

    const forgotPassword = async (event) => {
      const data = new FormData(event.target.form);
      const emailFieldVal = data.get('email');
      await axios({
          method: 'post', 
          url: "https://www.apartmentmii.com/resetpassword",
          data: {
              email: emailFieldVal
          }
      });
      toast.success('Password reset, you will receive an email shortly', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    useEffect(() => {
      getData();
    }, []);
    return (
          <div>
            <ToastContainer />
            {loggedIn ? <Redirect to={`/${apartmentName}/dashboard`} /> : 
              <div style={{ backgroundImage : `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className={!passwordCorrect ? styles["IndexWrapper"] : ""}>
                <NavLink className={classes.homeLink} to={`/${apartmentName}`} >
                  <HomeIcon className={classes.homeIcon} />
                </NavLink>
                <Container component="main" maxWidth="xs"
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100vh',
                }}
                >
                <CssBaseline />
                <Box
                    sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: '25px 50px',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    borderRadius: '5%',
                    marginTop: '0px !important'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    Property Manager Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onFocus={onFocus}
                        autoComplete="new-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Grid container className={classes.forgot}>
                        <Grid item xs>
                        <Button variant="outlined" onClick={forgotPassword}>
                            Forgot password?
                        </Button>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    </Box>
                </Box>
                </Container>
              </div>
            }
      </div>
    )
};

export default Dashboard;

// temp pass: qpWl00aBIx