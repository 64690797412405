import React, { useContext, useRef } from "react";
import { KeysFormContext, ExpandedSectionContext } from "../../Store";
import ImageUploader from "../CommentsSection";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import FontAwesome from "react-fontawesome";
import { FormGroup, Input, Label } from "reactstrap";
import styles from "./keysform.module.css";

const KeysForm = React.memo(({ Name, keysFormQuestions }) => {
  const formRef = useRef(null);
  const [expandedSection, setExpandedSection] = useContext(
    ExpandedSectionContext
  );
  let keysQuestions = JSON.parse(keysFormQuestions);
  const [keysFormAnswers, setKeysFormAnswers] = useContext(KeysFormContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeysFormAnswers((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOpen = () => {
    expandedSection === Name
      ? setExpandedSection("")
      : setExpandedSection(Name);
  };
  const onEntered = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const answeredQuestions = Object.keys(keysFormAnswers);
  const requiredFieldsFilled =
    answeredQuestions &&
    answeredQuestions.length &&
/*     answeredQuestions.includes("cakeys") &&
    answeredQuestions.includes("fdkeys") &&
    answeredQuestions.includes("mbkeys") &&
    answeredQuestions.includes("gkeys") */
    answeredQuestions.length === keysQuestions.length
      ? true
      : false;
  return (
    <div>
      <Button
        innerRef={formRef}
        className={styles["CollapseBtn"]}
        onClick={handleOpen}
        style={
          answeredQuestions.length && requiredFieldsFilled
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#02BD43",
                },
                backgroundColor: "#02BD43",
                marginBottom: "1rem",
                width: "100%",
              }
            : answeredQuestions.length && !requiredFieldsFilled
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#bd0202",
                },
                backgroundColor: "#bd0202",
                marginBottom: "1rem",
                width: "100%",
              }
            : {
                ":focus": {
                  backgroundColor: "#fafafa",
                },
                marginBottom: "1rem",
                width: "100%",
              }
        }
      >
        <p>KEYS FORM</p>
        <FontAwesome
          className="super-crazy-colors"
          name="angle-up"
          rotate={expandedSection === Name ? null : 180}
          size="lg"
          style={{
            marginTop: "5px",
            textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
          }}
        />
      </Button>
      <Collapse
        onEntered={onEntered}
        className={styles["Collapse"]}
        isOpen={expandedSection === Name}
      >
        <Card>
          <CardBody>
            <div id="contact-form">
              {keysQuestions.map(question => 
                <FormGroup
                className={styles["KeysFormGroup"]}
                >
                  <Label
                    // className={question.id.includes('other') ? styles[''] : styles["Required"]}
                    className={styles["Required"]}
                    for="exampleEmail"
                  >
                    {question.question}
                  </Label>
                  <Input
                    pattern="\d*"
                    value={keysFormAnswers[question.id]}
                    onChange={handleChange}
                    type={question.id == 'othernames' ? "text" : "number"}
                    // what other objects have been issued will bring up text keyboard not numeric ..
                    inputmode={question.id == 'othernames' ? 'text' : 'number'}
                    name={question.id}
                  />
                </FormGroup>
              )}
              {/* <FormGroup className={styles["KeysFormGroup"]}>
                <Label className={styles["Required"]} for="exampleEmail">
                  Quantity of Front Door Keys Issued
                </Label>
                <Input
                  pattern="\d*"
                  value={keysFormAnswers.fdkeys}
                  onChange={handleChange}
                  type="number"
                  name="fdkeys"
                />
              </FormGroup>
              <FormGroup className={styles["KeysFormGroup"]}>
                <Label className={styles["Required"]} for="exampleEmail">
                  Quantity of Mailbox Keys Issued
                </Label>
                <Input
                  pattern="\d*"
                  value={keysFormAnswers.mbkeys}
                  onChange={handleChange}
                  type="number"
                  name="mbkeys"
                />
              </FormGroup>
              <FormGroup className={styles["KeysFormGroup"]}>
                <Label className={styles["Required"]} for="exampleEmail">
                  Quantity of Community/Amenity Keys or Fobs Issued
                </Label>
                <Input
                  pattern="\d*"
                  value={keysFormAnswers.cakeys}
                  onChange={handleChange}
                  type="number"
                  name="cakeys"
                />
              </FormGroup>
              <FormGroup className={styles["KeysFormGroup"]}>
                <Label className={styles["Required"]} for="exampleEmail">
                  Quantity of Garage Opener or Keys Issued
                </Label>
                <Input
                  pattern="\d*"
                  value={keysFormAnswers.gkeys}
                  onChange={handleChange}
                  type="number"
                  name="gkeys"
                />
              </FormGroup>
              <FormGroup className={styles["KeysFormGroup"]}>
                <Label for="exampleEmail">
                  What other objects have been issued?
                </Label>
                <Input
                  value={keysFormAnswers.othernames}
                  onChange={handleChange}
                  type="text"
                  name="othernames"
                />
              </FormGroup>
              <FormGroup className={styles["KeysFormGroup"]}>
                <Label for="exampleEmail">Additional Keys Issued</Label>
                <Input
                  pattern="\d*"
                  value={keysFormAnswers.other}
                  onChange={handleChange}
                  type="number"
                  name="other"
                />
              </FormGroup> */}
            </div>
            {!answeredQuestions.length ? (
              ""
            ) : (
              <Button
                onClick={(e) => e.preventDefault()}
                style={
                  answeredQuestions.length && !requiredFieldsFilled
                    ? {
                        backgroundColor: "#bd0202",
                        color: "white",
                        pointerEvents: "none",
                      }
                    : {
                        backgroundColor: "#02BD43",
                        color: "white",
                        pointerEvents: "none",
                      }
                }
              >
                {answeredQuestions.length && !requiredFieldsFilled
                  ? "Incomplete"
                  : "Complete"}
              </Button>
            )}
            <ImageUploader name="keys" title="Keys to Unit" />
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
});

export default KeysForm;
