import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import Typography from '@mui/material/Typography';


const useStyles = makeStyles({
  body: {
    backgroundSize: 'cover',
    height: '100vh',
  }, 
  selectLabel: {
    marginTop: '25px', 
    marginBottom: '0px', 
    textAlign: 'left'
  }, 
  select: {
    marginTop: '0px !important', 
    marginBottom: '25px'
  }, 
  textField: {
    minWidth: '200px', 
    marginBottom: '15px'
  }, 
  selectForm: {
    width: '200px'
  }, 
  menuItem: {
      minHeight: '36px !important'
  }, 
  box: {
      padding: '50px 100px',
  }, 
  boxMobile: {
      padding: '5px 10px', 
  }, 
  containerMobile: {
      paddingLeft: '0px !important', 
      paddingRight: '0px !important'
  }, 
  tableHeader: {
    textAlign: 'center'
  }, 
  cancel: {
    marginLeft: '15px'
  }
});

const defaultValues = {
  name: '',
  email: '',
  apartment: '',
  password: ''
};
const EditUserForm = ({apartments, apartmentData, redirect, mobile, modalStatus}) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const [userId, setUserId] = useState('');

    const updateWithApartmentData = () => {
        if(!apartmentData.email.length || valuesUpdated) return;
        const {
            name, 
            email,
            apartment, 
            _id
        } = apartmentData;
        setFormValues({
            ...formValues, 
            name: name, 
            email: email, 
            apartment: apartment
        });
        setUserId(_id);
        setValuesUpdated(true);
    }

  const classes = useStyles();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
    console.log('form values', formValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      const token = sessionStorage.getItem('userToken');
      const response = await axios({
        method: "put",
        url: `https://www.apartmentmii.com/users?secret_token=${token}&user_id=${userId}`,
        data: formValues
      });
      modalStatus(true, true);
    }catch(error) {
      console.log('could not add user', error);
    }
  };

  const onClose = () => {
    modalStatus(true);
  }

  useEffect(() => {
    updateWithApartmentData();
  });
  return (
    <div className={classes.body}>
    <Box
      sx={{
      marginTop: '0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '5%',
      }}
      className={mobile ? classes.boxMobile : classes.box}
    >
      <Typography
          className={classes.tableHeader}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Edit Property Manager
      </Typography>
      <form onSubmit={handleSubmit}>
            <TextField 
              className={classes.textField}
              id="name-input"
              name="name"
              label="Name"
              type="text"
              value={formValues.name}
              onChange={handleInputChange}
            />

            <TextField 
              className={classes.textField}
              id="email-input"
              label="Email"
              type="text"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
            />

            <TextField 
              className={classes.textField}
              id="password-input"
              name="password"
              label="Password (Optional)"
              type="text"
              value={formValues.password}
              onChange={handleInputChange}
            />
            <Box sx={{ minWidth: 200 }}>
              <FormControl className={classes.selectForm} fullWidth variant="standard">
                <InputLabel className={classes.selectLabel} id="apartment-label">Apartment</InputLabel>
                <Select
                  className={classes.select}
                  id="apartment-select"
                  label="Apartment"
                  name="apartment"
                  value={formValues.apartment}
                  onChange={handleInputChange}
                >
                    <MenuItem className={classes.menuItem} key="0" value=""></MenuItem>
                  {apartments.map((apartment, index) => {
                    return (
                      <MenuItem className={classes.menuItem} key={index + 1} value={apartment.apartment}>{apartment.apartmentTitle}</MenuItem>
                    )
                  })}

                </Select>
              </FormControl>
            </Box>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          <Button onClick={onClose} className={classes.cancel} variant="outlined" color="primary" type="button">
              Cancel
          </Button>
      </form>
    </Box>
    </div>
  );
};
export default EditUserForm;