
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import React, {useEffect, useState} from "react";
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import randomstring from 'randomstring';


const useStyles = makeStyles({
    dashboardBody: {
        height: '100vh', 
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        textAlign: 'center', 
        position: 'absolute', 
        top: '0',
        overflowY: 'hidden'
    }, 
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer', 
        zIndex: '5'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    },
    root: {
        backgroundSize: 'cover',
        height: '100vh',
        '& .MuiPaper-root': {
            width: '400px !important', 
            alignItems: 'center', 
            borderRadius: '20px', 
            height: '400px', 
            justifyContent: 'center'
        }
    }, 
    selectLabel: {
        marginTop: '25px', 
        marginBottom: '0px', 
        textAlign: 'left'
    }, 
    select: {
        marginTop: '0px !important', 
        marginBottom: '25px'
    }, 
    textField: {
        marginBottom: '15px', 
        display: 'block', 
        '& .MuiInputBase-root': {
            width: '200px !important'
        }
    }, 
    modal: {
        height: '100%',
        width: '500px'
    }, 
    cancel: {
        marginLeft: '15px'
    }
});

const defaultValues = {
    name: '',
    email: '',
    apartment: '',
    password: randomstring.generate(10)
};

const AddUser = ({closeModal}) => {
    const [click, setClick] = useState(false);
    const [apartments, setApartments] = useState([]);
    const [formValues, setFormValues] = useState(defaultValues);

    const classes = useStyles();

    const retrieveApartments = async () => {
        try{
            const token = sessionStorage.getItem('userToken');
            const response = await axios({
                method: "get",
                url: `https://www.apartmentmii.com/apartments?secret_token=${token}`,
            });
            setApartments(response.data);
        }catch(error) {
            console.error('could not retrieve apartments', error);
        }
    }

    const onClose = () => {
        closeModal(true);
    }

    const userCreated = () => {
        closeModal(true, true);
    }
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setFormValues({
          ...formValues,
          [name]: value,
        });
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        try{
            const token = sessionStorage.getItem('userToken');
            const response = await axios({
                method: "post",
                url: `https://www.apartmentmii.com/users?secret_token=${token}`,
                data: formValues
            });
            userCreated();
        }catch(error) {
            console.log('could not add user', error);
        }
    };

    useEffect(() => {
        retrieveApartments();
    });

    return (
        <div className={classes.body}>
            <Dialog
                className={classes.root}
                fullWidth
                maxWidth="md"
                open={true}
            >
                <Typography
                    variant="h5"
                    id="tableTitle"
                    component="div"
                >
                    Add Property Manager
                </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField 
                            className={classes.textField}
                            id="name-input"
                            name="name"
                            label="Name"
                            type="text"
                            value={formValues.name}
                            onChange={handleInputChange}
                        />

                        <TextField 
                            className={classes.textField}
                            id="email-input"
                            label="Email"
                            type="text"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                        />
                        <Box sx={{ minWidth: 200 }}>
                        <FormControl className={classes.selectForm} fullWidth variant="standard">
                            <InputLabel className={classes.selectLabel} id="apartment-label">Apartment</InputLabel>
                            <Select
                                className={classes.select}
                                id="apartment-select"
                                label="Apartment"
                                name="apartment"
                                value={formValues.apartment}
                                onChange={handleInputChange}
                            >
                            {apartments.map((apartment, index) => {
                                return (
                                <MenuItem key={index} value={apartment.apartment}>{apartment.apartmentTitle}</MenuItem>
                                )
                            })}

                            </Select>
                        </FormControl>
                            </Box>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                        <Button onClick={onClose} className={classes.cancel} variant="outlined" color="primary" type="button">
                            Cancel
                        </Button>
                    </form>
            </Dialog>
        </div>
    )
}

export default AddUser;