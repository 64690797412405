import React, { useRef, useContext, useState, useEffect } from "react";
import { SignatureFormContext, ExpandedSectionContext } from "../../Store";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import FontAwesome from "react-fontawesome";
import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import "./signaturesform.css";

const SignaturesForm = React.memo(({ Name }) => {
  const formRef = useRef(null);
  const [expandedSection, setExpandedSection] = useContext(
    ExpandedSectionContext
  );
  const [mobile, setMobile] = useState(false);
  const [landscape, setLandscape] = useState(false);
  const [signatureFormImages, setSignatureFormImages] = useContext(
    SignatureFormContext
  );
  const sigCanvas = useRef({});

  const clear = (e) => {
    e.preventDefault();
    sigCanvas.current.clear();
  };
  const save = (e, close) => {
    e.preventDefault();
    const index = e.target.value;
    const arr = [...signatureFormImages];

    if (signatureFormImages[index]) {
      arr[index] = sigCanvas.current.getTrimmedCanvas().toDataURL("image/jpg");
      setSignatureFormImages(arr);
    } else {
      setSignatureFormImages((state) => [
        ...state,
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/jpg"),
      ]);
    }
  };
  const checkMobile = () => {
    if (
      /webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Take the user to a different screen here.
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  const setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setLandscape(false);
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      setLandscape(true);
    }
  };
  const handleOpen = () => {
    expandedSection === Name
      ? setExpandedSection("")
      : setExpandedSection(Name);
  };
  const onEntered = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const answeredQuestions = signatureFormImages.length;
  const expectedLength = 1;
  useEffect(() => {
    checkMobile();
    window.addEventListener("resize", function () {
      if (mobile) {
        setScreenOrientation();
      }
    });
  });
  return (
    <div>
      <Button
        innerRef={formRef}
        className="CollapseBtn"
        onClick={handleOpen}
        style={
          answeredQuestions && answeredQuestions >= expectedLength
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#02BD43",
                },
                backgroundColor: "#02BD43",
                marginBottom: "1rem",
                width: "100%",
              }
            : {
                ":focus": {
                  backgroundColor: "#fafafa",
                },
                marginBottom: "1rem",
                width: "100%",
              }
        }
      >
        <p>SIGNATURE FORM</p>
        <FontAwesome
          className="super-crazy-colors"
          name="angle-up"
          rotate={expandedSection === Name ? null : 180}
          size="lg"
          style={{
            marginTop: "5px",
            textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
          }}
        />
      </Button>
      <Collapse
        onEntered={onEntered}
        className="Collapse"
        isOpen={expandedSection === Name}
      >
        <Card>
          <CardBody>
            <div className="SignatureForm">
              <div className={"ContentWrapper"}>
                <h5 className={"Required"}>Resident's Signature</h5>
                {!landscape && mobile ? (
                  <p style={{ color: "blue" }}>
                    *Please Rotate Your Device To Open Signature Pad*
                  </p>
                ) : (
                  ""
                )}
                <Popup
                  className={"PopupContent"}
                  modal
                  disabled={
                    landscape && mobile
                      ? false
                      : !landscape && !mobile
                      ? false
                      : true
                  }
                  trigger={
                    <a
                      style={{ textAlign: "center", cursor: "pointer" }}
                      className={"SignatureBtn"}
                    >
                      Open Signature Pad
                    </a>
                  }
                  closeOnDocumentClick={false}
                >
                  {(close) => (
                    <>
                      {landscape ? (
                        <div>
                          <SignaturePad
                            ref={sigCanvas}
                            canvasProps={{
                              dotsize: 10,
                              className: "SignatureCanvas",
                            }}
                          />
                          {/* Button to trigger save canvas image */}
                          <button
                            value={0}
                            className={"SignatureBtn"}
                            onClick={(e) => save(e, close())}
                          >
                            Save
                          </button>
                          <button className={"SignatureBtn"} onClick={clear}>
                            Clear
                          </button>
                          <button className={"SignatureBtn"} onClick={close}>
                            Close
                          </button>
                        </div>
                      ) : !mobile ? (
                        <div>
                          <SignaturePad
                            ref={sigCanvas}
                            canvasProps={{
                              dotsize: 10,
                              className: "SignatureCanvas",
                            }}
                          />
                          {/* Button to trigger save canvas image */}
                          <button
                            value={0}
                            className={"SignatureBtn"}
                            onClick={(e) => save(e, close())}
                          >
                            Save
                          </button>
                          <button className={"SignatureBtn"} onClick={clear}>
                            Clear
                          </button>
                          <button className={"SignatureBtn"} onClick={close}>
                            Close
                          </button>
                        </div>
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          Please rotate your device
                        </p>
                      )}
                    </>
                  )}
                </Popup>

                {signatureFormImages[0] ? (
                  <img
                    src={signatureFormImages[0]}
                    alt="my signature"
                    style={{
                      height: "150px",
                      display: "block",
                      margin: "0 auto",
                      marginTop: "5%",
                      border: "1px solid black",
                      width: "200px",
                    }}
                  />
                ) : null}
              </div>
              <div className={"ContentWrapper"}>
                <h5>Resident's Signature</h5>
                {!landscape && mobile ? (
                  <p style={{ color: "blue" }}>
                    *Please Rotate Your Device To Open Signature Pad*
                  </p>
                ) : (
                  ""
                )}
                <Popup
                  className={"PopupContent"}
                  modal
                  disabled={
                    landscape && mobile
                      ? false
                      : !landscape && !mobile
                      ? false
                      : true
                  }
                  trigger={
                    <a
                      style={{ textAlign: "center", cursor: "pointer" }}
                      className={"SignatureBtn"}
                    >
                      Open Signature Pad
                    </a>
                  }
                  closeOnDocumentClick={false}
                >
                  {(close) => (
                    <>
                      <SignaturePad
                        ref={sigCanvas}
                        canvasProps={{
                          dotsize: 10,
                          className: "SignatureCanvas",
                        }}
                      />
                      {/* Button to trigger save canvas image */}
                      <button
                        value={1}
                        className={"SignatureBtn"}
                        onClick={(e) => save(e, close())}
                      >
                        Save
                      </button>
                      <button className={"SignatureBtn"} onClick={clear}>
                        Clear
                      </button>
                      <button className={"SignatureBtn"} onClick={close}>
                        Close
                      </button>
                    </>
                  )}
                </Popup>
                {/* if our we have a non-null image url we should 
      show an image and pass our imageURL state to it*/}
                {signatureFormImages[1] ? (
                  <img
                    src={signatureFormImages[1]}
                    alt="my signature"
                    style={{
                      height: "150px",
                      display: "block",
                      margin: "0 auto",
                      marginTop: "5%",
                      border: "1px solid black",
                      width: "200px",
                    }}
                  />
                ) : null}
              </div>
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
});

export default SignaturesForm;
