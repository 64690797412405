import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import { Redirect } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import VerticalTabs from './VerticalTabs';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
    dashboardBody: {
        height: '100vh', 
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        textAlign: 'center', 
        position: 'absolute', 
        top: '0', 
        overflow: 'hidden'
    }, 
    tabs: {
        position: 'absolute', 
        top: '0'
    },
    logo: {
        width: '15%', 
        minWidth: '250px',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        marginTop: '50px'
    }, 
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    bodyContainer: {
        position: 'relative', 
        width: '80%', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        height: '100%', 
        paddingLeft: '200px'
    }
});

const AdminResetPassword = () => {
    const [loggedIn, SetLoggedIn] = useState(sessionStorage.getItem('userToken') ? true : false);
    const [click, setClick] = useState(false);
    const [mobile, setMobile] = useState(false);

    const classes = useStyles();

    const handleClick = () => setClick(!click);

    const showMobile = () => {
        if(window.innerWidth <= 960){
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = sessionStorage.getItem('userToken');
        if(!token) {
            return;
        }

        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const resetCreds = {
            password: data.get('password'),
            verifyPassword: data.get('verifyPassword'),
        }


        if(resetCreds.password != resetCreds.verifyPassword){
            toast.error("Password fields don't match", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
  
        const serverResponse = await axios({
            url: `https://www.apartmentmii.com/users?secret_token=${token}`,
            method: 'put', 
            data: {
                password: resetCreds.password
            }
        }, {
          validateStatus: function(status){
            return status
          }
        });
        if(serverResponse.data.msg == 'User successfully updated'){
            toast.success('Password Updated Successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else {
            toast.error('Password reset failed, please try again', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    window.addEventListener('resize', showMobile);

    return (
        <>
            <ToastContainer />
            {!loggedIn && <Redirect to="/admin/login" />}
            <div className={classes.dashboardBody}>
                {mobile && !click ? 
                <MenuIcon className={classes.menu} onClick={handleClick} /> :
                <VerticalTabs className={classes.tabs}
                    initialTab={2}
                >
                    {mobile && click ? <CloseIcon className={classes.close} onClick={handleClick} /> : null}

                </VerticalTabs> 
                }

                <Container component="main" maxWidth="xs"
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100vh',
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        padding: '25px 50px',
                        position: 'absolute',
                        top: '30%',
                        transform: 'translateY(-50%)',
                        borderRadius: '5%',
                        marginTop: '0px !important'
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                        Password Reset
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="verifyPassword"
                            label="Verify Password"
                            id="verifyPassword"
                            /* onFocus={onFocus} */
                            autoComplete="new-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default AdminResetPassword;