import React, { useContext } from "react";
import styles from "./sectionheader.module.css";
import { FormAnswersContext, FormQuestionsContext, ConditionRatings } from "../../Store";

const SectionHeader = React.memo(({ title, name }) => {
  const [formAnswers, setFormAnswers] = useContext(FormAnswersContext);
  const [formQuestions, setFormQuestions] = useContext(FormQuestionsContext);
  const [conditionRatings, setConditionRatings] = useContext(ConditionRatings);
  const setStateGood = (e) => {
    e.preventDefault();
    formQuestions[name].map((question) => {
      return setFormAnswers((state) => ({
        ...state,
        [name]: { ...state[name], [question]: conditionRatings.length ? conditionRatings[0] : 'Good' },
      }));
    });
  };
  const setStateNA = (e) => {
    e.preventDefault();
    formQuestions[name].map((question) => {
      return setFormAnswers((state) => ({
        ...state,
        [name]: { ...state[name], [question]: "N/A" },
      }));
    });
  };
  return (
    <div>
      <h1 className={styles["Header"]}>{title}</h1>
      <div className={styles["MarkAllWrapper"]}>
        <button className={styles["MarkAll"]} onClick={setStateGood}>
          Mark all items as "{conditionRatings.length ? conditionRatings[0] : 'Good'}" in this section
        </button>
        <br />
        <button className={styles["MarkAll"]} onClick={setStateNA}>
          Mark all items as "N/A" in this section
        </button>
      </div>
    </div>
  );
});

export default SectionHeader;
