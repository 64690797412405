import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { makeStyles } from '@material-ui/styles';
import Dialog from './Confirm';
import axios from "axios";
// import { useHistory } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const useStyles = makeStyles({
    box: {
        width: '100%', 
        maxHeight: '80%', 
        overflow: 'scroll', 
        marginTop: '25px', 
        height: '80%'
    }, 
    boxMobile: {
        width: '100%', 
        maxHeight: '80%', 
        overflow: 'scroll', 
        marginTop: '25px', 
        height: '100%'
    }, 
    tableHeader: {
      flex: '0 !important', 
      fontSize: '24px !important', 
      marginLeft: '25px !important', 
      minWidth: '250px'
    }, 
    tableHeaderMobile: {
      flex: '0 !important', 
      fontSize: '24px !important', 
      marginLeft: '25px !important', 
      marginBottom: '50px !important', 
      position: 'absolute', 
      top: '25px', 
      left: '50px'
    }, 
    edit: {
        cursor: 'pointer', 
        '&:hover': {
            textDecoration: 'underline', 
            color: 'blue'
        }
    }, 
    delete: {
        cursor: 'pointer', 
        '&:hover': {
            textDecoration: 'underline', 
            color: 'blue'
        }
    },
    tableToolbar: {
        height: '100px'
    }, 
    tableToolbarMobile: {
        height: '250px'
    }, 
    headerCell: {
      paddingLeft: '36px !important', 
      fontSize: '15px !important', 
      fontWeight: '600 !important'
    }
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: 'Edit',
  },
  {
    id: 'apartment',
    numeric: true,
    disablePadding: false,
    label: 'Apartment',
  },
  {
    id: 'property_manager_name',
    numeric: true,
    disablePadding: false,
    label: 'Property Manager',
  },
  {
    id: 'property_manager_email',
    numeric: true,
    disablePadding: false,
    label: 'Property Manager Email',
  },
  {
    id: 'delete',
    numeric: true,
    disablePadding: false,
    label: 'Delete',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.headerCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {

  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
      className={props.mobile ? classes.tableToolbarMobile : classes.tableToolbar}
    >
        <Typography
          className={props.mobile ? classes.tableHeaderMobile : classes.tableHeader}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Property Managers
        </Typography>
        {props.children}
    </Toolbar>
  );
};



export default function EnhancedTable({mobile, data, children, refreshTable, EditUserModal}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [userToDelete, setUserToDelete] = React.useState('');
    const [userToDeleteId, setUserToDeleteId] = React.useState('');
    const [dialog, setDialog] = React.useState(false);

    // const history = useHistory();

    const rows = data;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const deleteUser = (event, data) => {
      if(event.target.nodeName !== 'td') event.target = event.target.closest('td');
      setUserToDeleteId(event.target.parentElement.id);
      setUserToDelete(event.target.previousElementSibling.previousElementSibling.innerHTML);
      setDialog(true);
    }

    const deleteUserResponse = async (remove, response) => {
        setDialog(remove);
        if(response){
            const token = sessionStorage.getItem('userToken');
            try{
                const response = await axios.delete(
                    `https://www.apartmentmii.com/users?secret_token=${token}`, 
                    {
                        data: {
                            id: userToDeleteId
                        }
                    }
                );
                setUserToDelete('');
                setUserToDeleteId('');
                refreshTable(true);
            }catch(error){
                console.log('could not delete user', error);
            }
        }
    }

    const editUser = (event) => {
      if(event.target.nodeName != 'td') event.target = event.target.closest('td');
      const rowId = event.target.parentElement.id;
      EditUserModal(true, rowId);
    }

    const classes = useStyles();

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
    <Box className={mobile ? classes.boxMobile : classes.box}>
        {dialog && 
        <Dialog
            user={userToDelete}
            removeDialog={deleteUserResponse}
        />}
        <Paper sx={{ width: '100%', mb: 2, position: 'relative', marginTop: '100px' }}>
        <EnhancedTableToolbar
            mobile={mobile}
        >
            {children}
        </EnhancedTableToolbar>
        <TableContainer>
            <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
            >
            <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
            >
            </EnhancedTableHead>
            <TableBody>

                {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                    <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        align="center"
                        id={row._id}
                    >
                        <TableCell align="center" onClick={(function(e){ editUser(e, data)})} className={classes.edit}><EditIcon /></TableCell>
                        <TableCell align="center" className="apartment">{row.apartment}</TableCell>
                        <TableCell align="center" className="manager">{row.manager}</TableCell>
                        <TableCell align="center" className="email">{row.manager_email}</TableCell>
                        <TableCell align="center" onClick={(function(e){ deleteUser(e, data)})} className={classes.delete}><DeleteIcon /></TableCell>
                    </TableRow>
                    );
                })}
                {emptyRows > 0 && (
                <TableRow
                    style={{
                    height: (53) * emptyRows,
                    }}
                >
                    <TableCell colSpan={6} />
                </TableRow>
                )}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{'& .MuiTablePagination-selectLabel': {marginBottom: '0px !important'}, '& .MuiTablePagination-displayedRows': {marginBottom: '0px !important'}}}
        />
        </Paper>
    </Box>
    );
}
