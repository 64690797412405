
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import { Redirect, Link } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import VerticalTabs from './components/VerticalTabs';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ManagersTable from './components/ManagersTable';
import Button from '@mui/material/Button';
import AddUser from './components/AddUser';
import EditUser from '../EditUser/EditUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles({
    dashboardBody: {
        height: '100vh', 
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        textAlign: 'center', 
        position: 'absolute', 
        top: '0', 
        overflow: 'hidden'
    }, 
    tabs: {
        position: 'absolute', 
        top: '0'
    },
    logo: {
        width: '15%', 
        minWidth: '250px',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        marginTop: '50px'
    }, 
    search: {
        marginTop: '0px !important',
        marginBottom: '0px !important',
        position: 'absolute !important', 
        top: '20px', 
        right: '30px',
        cursor: 'pointer',
        '& .MuiInput-root': {
            fontSize: '24px'
        }, 
        '& .MuiSvgIcon-root': {
            fontSize: '24px'
        }, 
        '& .MuiInputLabel-root': {
            fontSize: '18px', 
            left: '30%'
        }, 
        '& .css-i4bv87-MuiSvgIcon-root': {
            position: 'absolute', 
            right: '-30px', 
            cursor: 'pointer', 
            fontSize: '24px', 
            color: 'rgba(0, 0, 0, 0.54)'        }
    }, 
    searchMobile: {
        marginTop: '0px !important',
        marginBottom: '0px !important',
        position: 'absolute !important', 
        top: '175px', 
        right: '30px',
        cursor: 'pointer',
        '& .MuiInput-root': {
            fontSize: '24px'
        }, 
        '& .MuiSvgIcon-root': {
            fontSize: '24px'
        }, 
        '& .MuiInputLabel-root': {
            fontSize: '18px', 
            left: '20%'
        }, 
        '& .css-i4bv87-MuiSvgIcon-root': {
            position: 'absolute', 
            right: '-30px', 
            cursor: 'pointer', 
            fontSize: '24px', 
            color: 'rgba(0, 0, 0, 0.54)'
        }
    },
    paper: {
        marginLeft: 'clamp(300px, 20%, 20%)', 
        marginTop: '50px', 
        width: '70%', 
        height: '80%'
    }, 
    paperMobile: {
        marginTop: '50px', 
        width: '100%%', 
        height: '80%'
    },
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    addManager: {
        position: 'absolute !important', 
        left: '200px', 
        margin: '0px !important', 
        top: '45px',
        '&:hover': {
            color: 'white'
        }
    }, 
    bodyContainer: {
        position: 'relative', 
        width: '80%', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        height: '100%', 
        paddingLeft: '200px'
    }
});

const AdminPage = () => {
    const [loggedIn, SetLoggedIn] = useState(sessionStorage.getItem('userToken') ? true : false);
    const [click, setClick] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [searchData, setSearchData] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [searching, setSearching] = useState(false);
    const [data, setData] = useState([]);
    const [showAddUser, setShowAddUser] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false);
    const [editUserId, setEditUserId] = useState('');
    const [userAdded, setUserAdded] = useState(false);

    const classes = useStyles();

    const handleClick = () => setClick(!click);

    const showMobile = () => {
        if(window.innerWidth <= 960){
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    const refreshTable = (status) => {
        if(status) retrievePropertyManagers();
    }

    const retrievePropertyManagers = async () => {
        try{
            const token = sessionStorage.getItem('userToken');
            const response = await axios({
                method: "get",
                url: `https://www.apartmentmii.com/users?secret_token=${token}`,
            });
            if(response.data.length){
                const tableData = [];
                response.data.forEach((manager, index) => {
                    const dataObj = {
                        apartment: manager.apartment, 
                        manager: manager.name, 
                        manager_email: manager.email,
                        _id: manager._id,
                        key: index
                    }
                    tableData.push(dataObj);
                });
                setData(tableData);
            }
        }catch(error){
            console.log('users error', error);
        }   
    }

    const onSearchChange = (event) => {
        setSearchVal(event.target.value);
        if(!searchVal.length){
            setSearchData([]);
            setSearching(false);
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            onSearch();
        }
    }

    const onSearch = () => {
        setSearching(true);
        setSearchData(data.filter((items) => {
            return items.apartment?.toLowerCase().includes(searchVal.toLowerCase()) || items.manager?.toLowerCase().includes(searchVal.toLowerCase()) || items.manager_email?.toLowerCase().includes(searchVal.toLowerCase());
        }));
    }

    const clearSearch = () => {
        setSearching(false);
        setSearchVal('');
    }

    const addUserPage = () => {
        setShowAddUser(true);
    }

    const closeModal = (close, userAdded) => {
        if(close){
            setShowAddUser(false);
        }
        if(userAdded){
            toast.success("User Created Successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            refreshTable(true);
        }
    }

    const closeEditModal = (status, userEdited) => {
        if(status){
            setShowEditUser(false);
        }
        if(userEdited){
            toast.success("User Successfully Updated!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            refreshTable(true);
        }
    }

    const editUserModal = (status, userId) => {
        if(status) {
            setShowEditUser(true);
            setEditUserId(userId);
        }
    }

  
    window.addEventListener('resize', showMobile);
  
    useEffect(() => {
        showMobile();
        retrievePropertyManagers();
    }, []);
    return (
        <>
            <ToastContainer />
            {!loggedIn && <Redirect to="/admin/login" />}
            <div className={classes.dashboardBody}>
                {mobile && !click ? 
                <MenuIcon className={classes.menu} onClick={handleClick} /> :
                <VerticalTabs className={classes.tabs}
                    initialTab={0}
                >
                    {mobile && click ? <CloseIcon className={classes.close} onClick={handleClick} /> : null}

                </VerticalTabs> 
                }
                {showAddUser && 
                <AddUser
                    closeModal={closeModal}
                />}
                {showEditUser && 
                <EditUser 
                    userId={editUserId}
                    closeModal={closeEditModal}
                />
                }
                <div className={classes.bodyContainer}>
                    <Button onClick={addUserPage} variant="contained" className={classes.addManager}>Add Property Manager</Button>
                    <ManagersTable 
                        mobile={mobile}
                        data={searching && searchVal.length ? searchData : data}
                        sx={{position: 'relative', marginTop: '50px'}}
                        refreshTable={refreshTable}
                        EditUserModal={editUserModal}
                    >
                        <TextField
                            id="input-with-icon-textfield"
                            label="Search ..."
                            onChange={onSearchChange}
                            value={searchVal}
                            className={mobile ? classes.searchMobile : classes.search}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        sx={{position: 'absolute !important', right: '0', fontSize: 'medium', cursor: 'pointer'}}
                                        onClick={onSearch}
                                    />
                                    {searchVal.length ? (<HighlightOffIcon className={classes.clearIcon} onClick={clearSearch} />) : null}

                                </InputAdornment>
                                
                            ),
                            }}
                            variant="standard"
                        />
                    </ManagersTable>
                </div>
            </div>
        </>
    )
}

export default AdminPage;