import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import { Redirect } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import VerticalTabs from './VerticalTabs';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import AddKeys from './AddApartmentComponents/AddKeys';
import AddSurvey from './AddApartmentComponents/AddSurvey';
import Dining from './AddApartmentComponents/Dining';
import Kitchen from './AddApartmentComponents/Kitchen';
import Appliances from './AddApartmentComponents/Appliances';
import Living from './AddApartmentComponents/Living';
import Other from './AddApartmentComponents/Other';
import Bedroom from './AddApartmentComponents/Bedroom';
import Bathroom from './AddApartmentComponents/Bathroom';
import ConditionRating from './AddApartmentComponents/ConditionRating';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import randomstring from 'randomstring';
import reviewPage from './reviewPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
    dashboardBody: {
        height: '100vh', 
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        textAlign: 'center', 
        position: 'absolute', 
        top: '0', 
        overflow: 'hidden'
    }, 
    tabs: {
        position: 'absolute', 
        top: '0'
    },
    logo: {
        width: '15%', 
        minWidth: '250px',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        marginTop: '50px'
    }, 
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    bodyContainer: {
        position: 'relative', 
        width: '80%', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        height: '100%', 
        paddingLeft: '200px'
    }, 
    input: {
        width: '40% !important', 
        display: 'inline-block !important', 
        marginLeft: '20px !important', 
        marginRight: '20px !important'
    }, 
    add: {
        marginBottom: '0px', 
        marginLeft: '10px', 
        fontSize: '14px'
    }, 
    keysAuto: {
        marginTop: '15px'
    }, 
    numberSelect: {
        width: '45% !important', 
        marginLeft: '15px !important', 
        marginRight: '15px !important'
    }, 
    inlineSelect: {
        width: '40% !important', 
        marginLeft: '15px !important', 
        marginRight: '15px !important', 
        marginTop: '16px !important', 
        marginBottom: '8px !important'
    },
    Autocomplete: {
        marginTop: '20px'
    }, 
    apartmentName: {
        backgroundColor: 'yellow'
    }, 
    tooltip: {
        fontSize: '15px !important'
    }
});

const keysOptions = [
    {
        id: 'fdkeys', 
        question: 'Quantity of Front Door Keys Issued'
    }, 
    {
        id: 'mbkeys', 
        question: 'Quantity of Mailbox Keys Issued'
    },
    {
        id: 'cakeys', 
        question: 'Quantity of Community/Amenity Keys or Fobs Issued'
    },
    {
        id: 'gkeys', 
        question: 'Quantity of Garage Opener or Keys Issued'
    },
    {
        id: 'othernames', 
        question: 'What other objects have been issued?'
    },
    {
        id: 'other', 
        question: 'Additional Keys Issued'
    },
];

const surveyQuestions = [
    {
        id: '0', 
        question: 'Was the office team friendly & helpful?'
    },
    {
        id: '1', 
        question: 'Was your apartment ready when you came to move-in?'
    },
    {
        id: '2', 
        question: 'How satisfied are you with the cleanliness of your apartment?'
    },
    {
        id: '3', 
        question: 'Is everything in working order (appliances, door locks, faucets, etc.)...'
    },
    {
        id: '10', 
        question: 'Overall, how satisfied are you with your new apartment?'
    },
];

const diningRoomOptions = [
    "Walls and Ceilings",
    "Flooring",
    "Light Switches, Outlets",
    "Light Fixture(s), Bulb(s)",
    "Door & Door Hardware",
    "Window(s) & Screen(s)",
    "Blinds/Window Coverings",
    "Furniture (if any)",
    "Other"
];

const kitchenRoomOptions = [
    "Walls and Ceilings",
    "Flooring",
    "Counters",
    "Sink, Faucet",
    "Drain, Plumbing",
    "Garbage Disposal",
    "Light Fixture(s), Bulb(s)",
    "Light Switches, Outlets",
    "Door & Door Hardware",
    "Window(s) & Screens(s)",
    "Blinds/Window Coverings",
    "Cabinets",
    "Other"
];

const appliancesRoomOptions = [
    "Stove Top",
    "Oven",
    "Range Hood",
    "Refrigerator",
    "Dishwasher",
    "Microwave",
    "Washer",
    "Dryer",
    "Other"
];

const livingRoomOptions = [
    "Walls and Ceilings",
    "Flooring",
    "Light Fixture(s), Bulb(s)",
    "Ceiling Fan",
    "Light Switches, Outlets",
    "Closet Door(s)",
    "Door & Door Hardware",
    "Window(s) & Screens(s)",
    "Blinds/Window Coverings",
    "Furniture (if any)",
    "Other"
];

const otherRoomOptions = [
    "Entry Door(s)",
    "Furnace/Heater",
    "Air Conditioning",
    "Fireplace",
    "Balcony, Patio, Terrace",
    "Lawn, Ground Covering",
    "Garage or Parking Area",
    "Storage",
    "Water Heater",
    "Other"
];

const bedroomRoomOptions = [
    "Walls and Ceilings",
    "Flooring",
    "Light Switches, Outlets",
    "Closet Door(s)",
    "Door & Door Hardware",
    "Window(s) & Screen(s)",
    "Blinds/Window Coverings",
    "Furniture (if any)",
    "Other"
];

const bathroomRoomOptions = [
    "Walls and Ceilings",
    "Flooring",
    "Counters",
    "Sink, Faucet",
    "Shower/Tub, Faucet",
    "Drains, Plumbing",
    "Shower Door",
    "Toilet, Seat",
    "Caulking",
    "Towel Rack(s)",
    "Medicine Cab/Mirror",
    "Exhaust Fan",
    "Vanity Cabinet",
    "Light Fixture(s), Bulb(s)",
    "Light Switches, Outlets",
    "Linen Closet",
    "Door & Door Hardware",
    "Window(s) & Screen(s)",
    "Blinds/Window Coverings",
    "Other"
];

const conditionRatingOptions = [
    'Excellent', 
    'Very Good', 
    'Good', 
    'Average', 
    'Fair',
    'Poor'
];

const defaultConditionRatings = [
    'Good', 
    'Fair', 
    'Poor'
]

const AddApartment = () => {
    const [loggedIn, SetLoggedIn] = useState(sessionStorage.getItem('userToken') ? true : false);
    const [click, setClick] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [showAddKey, setShowAddKey] = useState(false);
    const [keyOptions, setKeyOptions] = useState(keysOptions);
    const [selectedKeys, setSelectedKeys] = useState(keysOptions);
    const [surveyOptions, setSurveyOptions] = useState(surveyQuestions);
    const [showAddSurvey, setShowAddSurvey] = useState(false);
    const [selectedSurveys, setSelectedSurveys] = useState(surveyQuestions);
    const [diningOptions, setDiningOptions] = useState(diningRoomOptions);
    const [showAddDining, setShowAddDining] = useState(false);
    const [selectedDining, setSelectedDining] = useState(diningRoomOptions);
    const [kitchenOptions, setKitchenOptions] = useState(kitchenRoomOptions);
    const [showAddKitchen, setShowAddKitchen] = useState(false);
    const [selectedKitchen, setSelectedKitchen] = useState(kitchenRoomOptions);
    const [applianceOptions, setApplianceOptions] = useState(appliancesRoomOptions);
    const [showAddAppliance, setShowAddAppliance] =  useState(false);
    const [selectedAppliances, setSelectedAppliances] = useState(appliancesRoomOptions);
    const [livingOptions, setLivingOptions] = useState(livingRoomOptions);
    const [showAddLiving, setShowAddLiving] = useState(false);
    const [selectedLiving, setSelectedLiving] = useState(livingRoomOptions);
    const [otherOptions, setOtherOptions] = useState(otherRoomOptions);
    const [showAddOther, setShowAddOther] = useState(false);
    const [selectedOther, setSelectedOther] = useState(otherRoomOptions);
    const [numberOfBedrooms, setNumberOfBedrooms] = useState(1);
    const [numberOfBathrooms, setNumberOfBathrooms] = useState(1);
    const [bedroomOptions, setBedroomOptions] = useState(bedroomRoomOptions);
    const [showAddBedroom, setShowAddBedroom] = useState(false);
    const [selectedBedroom, setSelectedBedroom] = useState(bedroomRoomOptions);
    const [bathroomOptions, setBathroomOptions] = useState(bathroomRoomOptions);
    const [showAddBathroom, setShowAddBathroom] = useState(false);
    const [selectedBathroom, setSelectedBathroom] = useState(bathroomRoomOptions);
    const [showAddConditionRatings, setShowAddConditionRatings] = useState(false);
    const [selectedConditionRatings, setSelectedConditionRatings] = useState([]);
    const [conditionRatingsOptions, setConditionRatingsOptions] = useState(conditionRatingOptions)
    const [apartmentName, setApartmentName] = useState('');
    const [reviewPageMinimum, setReviewPageMinimum] = useState(5);
    const [goodReviewImage, setGoodReviewImage] = useState();

    const classes = useStyles();

    const handleClick = () => setClick(!click);

    const showMobile = () => {
        if(window.innerWidth <= 960){
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = sessionStorage.getItem('userToken');
        if(!token) {
            return;
        }

        const data = new FormData(event.currentTarget);

        const apartmentTitle = data.get('apartment');
        const email = data.get('email');
        const link = data.get('link');
        const logo = data.get('logo');
        const backgroundImage = data.get('backgroundImage');
        const backgroundColor = data.get('backgroundColor');
        const passwordColor = data.get('passwordColor');
        const termsAgreement = data.get('termsAgreement');
        const termsCheckboxLabel = data.get('termsCheckboxLabel');
        const accessCode = data.get('accessCode');
        const badRatingMessage = data.get('badReviewText');
        const badRatingMessageColor = data.get('badReviewTextColor');
        const goodReviewText = data.get('goodReviewText');

        const apartmentFormDetails = {
            apartmentTitle,
            email, 
            link,
            logo,
            backgroundImage,
            backgroundColor,
            termsAgreement,
            termsCheckboxLabel,
            accessCode,
            dining: selectedDining, 
            kitchen: selectedKitchen, 
            appliances: selectedAppliances, 
            living: selectedLiving, 
            other: selectedOther, 
            keys: selectedKeys, 
            surveyQuestions: selectedSurveys,
            residentPasswordColor: passwordColor, 
            conditionRatings: selectedConditionRatings,
            reviewPageRatingMimimum: reviewPageMinimum, 
            badRatingMessage: badRatingMessage, 
            badRatingMessageColor: badRatingMessageColor, 
        }

        if(!selectedDining.length || !selectedKitchen.length || !selectedAppliances.length || !selectedLiving.length || !selectedOther.length || !selectedKeys.length || !selectedSurveys.length
            || !apartmentTitle.length || !email.length || !link.length || !logo.length || !backgroundImage.length || !backgroundColor.length || !termsAgreement.length || !termsCheckboxLabel.length
            || !accessCode.length){
                toast.error("Some fields are missing, please verify the form", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored'
                }); 
                return;
        }

        if(numberOfBedrooms == 1){
            apartmentFormDetails.bedone = selectedBedroom;
        }else if (numberOfBedrooms == 2){
            apartmentFormDetails.bedone = selectedBedroom;
            apartmentFormDetails.bedtwo = selectedBedroom;
        }else if(numberOfBedrooms == 3){
            apartmentFormDetails.bedone = selectedBedroom;
            apartmentFormDetails.bedtwo = selectedBedroom;
            apartmentFormDetails.bedthree = selectedBedroom;
        }

        if(numberOfBathrooms == 1){
            apartmentFormDetails.bathone = selectedBathroom;
        }else if(numberOfBathrooms == 2){
            apartmentFormDetails.bathone = selectedBathroom;
            apartmentFormDetails.bathtwo = selectedBathroom;
        }else if(numberOfBathrooms == 3){
            apartmentFormDetails.bathone = selectedBathroom;
            apartmentFormDetails.bathtwo = selectedBathroom;
            apartmentFormDetails.baththree = selectedBathroom;
        }
        apartmentFormDetails.useStripe = false;

        apartmentFormDetails.apartment = apartmentName;

        const reviewPageDetails = {
            link: apartmentFormDetails.link, 
            reviewText: goodReviewText, 
            image: goodReviewImage
        }

        apartmentFormDetails.reviewPage = reviewPage(reviewPageDetails);


        try{
            const response = await axios({
                method: "post",
                url: `https://www.apartmentmii.com/apartments?secret_token=${token}`,
                data: {
                    apartmentFormDetails
                }
            });
            toast.success("Apartment Succesfully Created!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }catch(error){
            console.log('error', error);
        }
    }

    const changeApartmentName = (event) => {
        setApartmentName(event.target.value.toLowerCase().replaceAll(' ', '-'));
    }
    // condition ratings
    const addConditionRatingsPage = () => {
        setShowAddConditionRatings(true);
    }

    const addConditionRatingOption = (option) => {
        setConditionRatingsOptions([...conditionRatingsOptions, option]);
    }

    let conditionRatingsList = conditionRatingsOptions;

    const refreshConditionRatings = () => {
        conditionRatingsList = conditionRatingsOptions;
    }

    const handleConditionRatingsCategoriesChange = (event, value) => {
        setSelectedConditionRatings(value);
    }

    // keys
    const addKeyPage = () => {
        setShowAddKey(true);
    }

    const addKeyOption = (option) => {
        const id = randomstring.generate({
            length: 4, 
            charset: 'alphabetic'
        });
        const keyOption = {
            id, 
            question: option
        }
        setKeyOptions([...keyOptions, keyOption]);
    }

    let keyOptionsList = keyOptions;

    const refreshKeys = () => {
        keyOptionsList = keyOptions;
    }

    const handleKeyCategoriesChange = (event, value) => {
        setSelectedKeys(value);
    }

    // surveys
    const addSurveyPage = () => {
        setShowAddSurvey(true);
    }

    const addSurveyOption = (option) => {
        const id = (surveyOptions.length).toString();
        const surveyOption = {
            id, 
            question: option
        }
        setSurveyOptions([...surveyOptions, surveyOption]);
    }

    let surveyOptionsList = surveyOptions;

    const refreshSurveys = () => {
        surveyOptionsList = surveyOptions;
    }

    const handleSurveyCategoriesChange = (event, value) => {
        setSelectedSurveys(value);
    }

    // dining
    const addDiningPage = () => {
        setShowAddDining(true);
    }

    const addDiningOption = (option) => {
        setDiningOptions([...diningOptions, option]);
    }

    let diningOptionsList = diningOptions;

    const refreshDining = () => {
        diningOptionsList = diningOptions;
    }

    const handleDiningCategoriesChange = (event, value) => {
        setSelectedDining(value);
    }

    // kitchen
    const addKitchenPage = () => {
        setShowAddKitchen(true);
    }

    const addKitchenOption = (option) => {
        setKitchenOptions([...kitchenOptions, option]);
    }

    let kitchenOptionsList = kitchenOptions;

    const refreshKitchen = () => {
        kitchenOptionsList = kitchenOptions;
    }

    const handleKitchenCategoriesChange = (event, value) => {
        setSelectedKitchen(value);
    }

    // appliances
    const addAppliancePage = () => {
        setShowAddAppliance(true);
    }

    const addApplianceOption = (option) => {
        setApplianceOptions([...applianceOptions, option]);
    }

    let applianceOptionsList = applianceOptions;

    const refreshAppliances = () => {
        applianceOptionsList = applianceOptions;
    }

    const handleApplianceCategoriesChange = (event, value) => {
        setSelectedAppliances(value);
    }

    // living
    const addLivingPage = () => {
        setShowAddLiving(true);
    }

    const addLivingOption = (option) => {
        setLivingOptions([...livingOptions, option]);
    }

    let livingOptionsList = livingOptions;

    const refreshLiving = () => {
        livingOptionsList = livingOptions;
    }

    const handleLivingCategoriesChange = (event, value) => {
        setSelectedLiving(value);
    }

    // other
    const addOtherPage = () => {
        setShowAddOther(true);
    }

    const addOtherOption = (option) => {
        setOtherOptions([...otherOptions, option]);
    }

    let otherOptionsList = otherOptions;

    const refreshOther = () => {
        otherOptionsList = otherOptions;
    }

    const handleOtherCategoriesChange = (event, value) => {
        setSelectedOther(value);
    }

    // bedroom
    const addBedroomPage = () => {
        setShowAddBedroom(true);
    }

    const addBedroomOption = (option) => {
        setBedroomOptions([...bedroomOptions, option]);
    }

    let bedroomOptionsList = bedroomOptions;

    const refreshBedroom = () => {
        bedroomOptionsList = bedroomOptions;
    }

    const handleBedroomCategoriesChange = (event, value) => {
        setSelectedBedroom(value);
    }

    // bathroom
    const addBathroomPage = () => {
        setShowAddBathroom(true);
    }

    const addBathroomOption = (option) => {
        setBathroomOptions([...bathroomOptions, option]);
    }

    let bathroomOptionsList = bathroomOptions;

    const refreshBathroom = () => {
        bathroomOptionsList = bathroomOptions;
    }

    const handleBathroomCategoriesChange = (event, value) => {
        setSelectedBathroom(value);
    }

    const closeConditionRatingsModal = (close, conditionAdded) => {
        if(close){
            setShowAddConditionRatings(false)
        }
        if(conditionAdded){
            addConditionRatingOption(conditionAdded);
        }
    }

    const closeKeyModal = (close, keyAdded) => {
        if(close){
            setShowAddKey(false);
        }
        if(keyAdded){
            addKeyOption(keyAdded);
        }
    }

    const closeSurveyModal = (close, surveyAdded) => {
        if(close){
            setShowAddSurvey(false);
        }
        if(surveyAdded){
            addSurveyOption(surveyAdded);
        }
    }

    const closeDiningModal = (close, diningCategoryAdded) => {
        if(close){
            setShowAddDining(false);
        }
        if(diningCategoryAdded){
            addDiningOption(diningCategoryAdded);
        }
    }

    const closeKitchenModal = (close, kitchenCategoryAdded) => {
        if(close){
            setShowAddKitchen(false);
        }
        if(kitchenCategoryAdded){
            addKitchenOption(kitchenCategoryAdded);
        }
    }

    const closeApplianceModal = (close, applianceCategoryAdded) => {
        if(close){
            setShowAddAppliance(false);
        }
        if(applianceCategoryAdded){
            addApplianceOption(applianceCategoryAdded);
        }
    }

    const closeLivingModal = (close, livingCategoryAdded) => {
        if(close){
            setShowAddLiving(false);
        }
        if(livingCategoryAdded){
            addLivingOption(livingCategoryAdded);
        }
    }

    const closeOtherModal = (close, otherCategoryAdded) => {
        if(close){
            setShowAddOther(false);
        }
        if(otherCategoryAdded){
            addOtherOption(otherCategoryAdded);
        }
    }

    const closeBedroomModal = (close, bedroomCategoryAdded) => {
        if(close){
            setShowAddBedroom(false);
        }
        if(bedroomCategoryAdded){
            addBedroomOption(bedroomCategoryAdded);
        }
    }

    const closeBathroomModal = (close, bathroomCategoryAdded) => {
        if(close){
            setShowAddBathroom(false);
        }
        if(bathroomCategoryAdded){
            addBathroomOption(bathroomCategoryAdded);
        }
    }

    const handleBedroomChange = (event) => {
        setNumberOfBedrooms(event.target.value);
    }

    const handleMinimumRatingChange = (event) => {
        setReviewPageMinimum(event.target.value);
    }


    const handleBathroomChange = (event) => {
        setNumberOfBathrooms(event.target.value);
    }


    const imageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', function(){
            setGoodReviewImage(reader.result);
        });

        if(file){
            reader.readAsDataURL(file);
        }
    }

    window.addEventListener('resize', showMobile);


    return (
        <>
            <ToastContainer />
            {!loggedIn && <Redirect to="/admin/login" />}
            <div className={classes.dashboardBody}>
                {mobile && !click ? 
                <MenuIcon className={classes.menu} onClick={handleClick} /> :
                <VerticalTabs className={classes.tabs}
                    initialTab={1}
                >
                    {mobile && click ? <CloseIcon className={classes.close} onClick={handleClick} /> : null}

                </VerticalTabs> 
                }

                <Container component="main" maxWidth="xs"
                    sx={{
                        position: 'relative',
                        width: '800px',
                        minWidth: '800px',
                        height: '100vh',
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        padding: '25px 50px',
                        position: 'absolute',
                        top: '45%',
                        transform: 'translateY(-50%)',
                        borderRadius: '5%',
                        marginTop: '0px !important',
                        width: '800px', 
                        height: '85%', 
                        maxHeight: '85%', 
                        overflow: 'scroll'
                        }}
                    >
                        <ApartmentIcon sx={{ m: 1 }}>
                        </ApartmentIcon>
                        <Typography component="h1" variant="h5">
                        Add Apartment
                        </Typography>
                        <Typography component="h8" variant="h8">
                            New Apartment URL: https://www.apartmentmii.com/<span className={classes.apartmentName}>{apartmentName}</span>
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="apartment"
                                label="Apartment Title"
                                name="apartment"
                                autoFocus
                                className={classes.input}
                                onChange={changeApartmentName}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="email"
                                label="Email"
                                id="email"
                                className={classes.input}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="link"
                                label="Submit Redirect Link"
                                id="link"
                                className={classes.input}
                                placeholder='Include "http"...'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="logo"
                                label="Logo Image Url"
                                id="logo"
                                className={classes.input}
                                placeholder='Include "http"...'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="backgroundImage"
                                label="Background Image Url"
                                id="backgroundImage"
                                className={classes.input}
                                placeholder='Include "http"...'
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="backgroundColor"
                                label="Background Color"
                                id="backgroundColor"
                                type="color"
                                className={classes.input}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                multiline
                                name="termsAgreement"
                                label="Terms Agreement"
                                id="termsAgreement"
                                className={classes.input}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="termsCheckboxLabel"
                                label="Terms Checkbox Label"
                                id="termsCheckboxLabel"
                                placeholder="I acknowledge that I have read..."
                                className={classes.input}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="passwordColor"
                                label="Password Box Color"
                                id="passwordColor"
                                type="color"
                                className={classes.input}
                            />
                            <FormControl fullWidth className={classes.inlineSelect}>
                                <InputLabel id="reviewPageMinimum">Review Page Mimimum Rating</InputLabel>
                                <Select
                                    labelId="reviewPageMinimum"
                                    id="reviewPageMinimum-select"
                                    value={reviewPageMinimum}
                                    label="Review Page Minimum Rating"
                                    onChange={handleMinimumRatingChange}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                multiline
                                name="badReviewText"
                                label="Poor Review Page Text"
                                id="badReviewText"
                                className={classes.input}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="badReviewTextColor"
                                label="Poor Review Page Text Color"
                                id="badReviewTextColor"
                                type="color"
                                className={classes.input}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                multiline
                                name="goodReviewText"
                                label="Good Review Page Text"
                                id="goodReviewText"
                                className={classes.input}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="goodReviewImage"
                                label="Good Review Page Image (optional)"
                                id="goodReviewImage"
                                type="file"
                                accept="image/*"
                                onChange={imageUpload}
                                className={classes.input}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="accessCode"
                                label="Access Code"
                                id="accessCode"
                                className={classes.input}
                            />

                            <Box sx={{ minWidth: 120, marginBottom: '25px', marginTop: '10px' }}>
                                <FormControl fullWidth className={classes.numberSelect}>
                                    <InputLabel id="numOfBedroomsLabel">Number of Bedrooms</InputLabel>
                                    <Select
                                        labelId="numOfBedroomsLabel"
                                        id="demo-simple-select"
                                        value={numberOfBedrooms}
                                        label="Number of Bathrooms"
                                        onChange={handleBedroomChange}
                                    >
                                        <MenuItem value={1}>One</MenuItem>
                                        <MenuItem value={2}>Two</MenuItem>
                                        <MenuItem value={3}>Three</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth className={classes.numberSelect}>
                                    <InputLabel id="numOfBathroomsLabel">Number of Bathrooms</InputLabel>
                                    <Select
                                        labelId="numOfBathroomsLabel"
                                        id="demo-simple-select"
                                        value={numberOfBathrooms}
                                        label="Number of Bathrooms"
                                        onChange={handleBathroomChange}
                                    >
                                        <MenuItem value={1}>One</MenuItem>
                                        <MenuItem value={2}>Two</MenuItem>
                                        <MenuItem value={3}>Three</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Tooltip title="Add conditions in order of best to worst" classes={{tooltip: classes.tooltip}}>
                                <Autocomplete
                                    className={classes.Autocomplete}
                                    multiple
                                    // limitTags={2}
                                    id="conditionRatings"
                                    name="conditionRatings"
                                    options={conditionRatingsList}
                                    getOptionLabel={(option) => option}
                                    disableCloseOnSelect
                                    onChange={handleConditionRatingsCategoriesChange}
                                    defaultValue={selectedConditionRatings}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Condition Ratings" placeholder="Category" onClick={refreshConditionRatings} />
                                    )}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                        </li>
                                    )}
                                />
                            </Tooltip>
                            
                            {showAddConditionRatings && <ConditionRating 
                                closeModal={closeConditionRatingsModal}
                            />}

                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addConditionRatingsPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                id="bedroomQuestions"
                                name="bedroomQuestions"
                                options={bedroomOptionsList}
                                getOptionLabel={(option) => option}
                                disableCloseOnSelect
                                onChange={handleBedroomCategoriesChange}
                                defaultValue={bedroomOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Bedroom Categories" placeholder="Category" onClick={refreshBedroom} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                )}
                            />
                            {showAddBedroom && <Bedroom 
                                closeModal={closeBedroomModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addBedroomPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                id="bathroomQuestions"
                                options={bathroomOptionsList}
                                getOptionLabel={(option) => option}
                                disableCloseOnSelect
                                onChange={handleBathroomCategoriesChange}
                                defaultValue={bathroomOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Bathroom Categories" placeholder="Category" onClick={refreshBathroom} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                )}
                            />
                            {showAddBathroom && <Bathroom 
                                closeModal={closeBathroomModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addBathroomPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                id="diningQuestions"
                                options={diningOptionsList}
                                getOptionLabel={(option) => option}
                                disableCloseOnSelect
                                onChange={handleDiningCategoriesChange}
                                defaultValue={diningOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Dining Categories" placeholder="Category" onClick={refreshDining} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                )}
                            />
                            {showAddDining && <Dining 
                                closeModal={closeDiningModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addDiningPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                id="kitchenQuestions"
                                options={kitchenOptionsList}
                                getOptionLabel={(option) => option}
                                disableCloseOnSelect
                                onChange={handleKitchenCategoriesChange}
                                defaultValue={kitchenOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Kitchen Categories" placeholder="Category" onClick={refreshKitchen} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                )}
                            />
                            {showAddKitchen && <Kitchen 
                                closeModal={closeKitchenModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addKitchenPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                id="applianceQuestions"
                                options={applianceOptionsList}
                                getOptionLabel={(option) => option}
                                disableCloseOnSelect
                                onChange={handleApplianceCategoriesChange}
                                defaultValue={applianceOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Appliance Categories" placeholder="Category" onClick={refreshAppliances} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                )}
                            />
                            {showAddAppliance && <Appliances 
                                closeModal={closeApplianceModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addAppliancePage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                id="livingQuestions"
                                options={livingOptionsList}
                                getOptionLabel={(option) => option}
                                disableCloseOnSelect
                                onChange={handleLivingCategoriesChange}
                                defaultValue={livingOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Living Categories" placeholder="Category" onClick={refreshLiving} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                )}
                            />
                            {showAddLiving && <Living 
                                closeModal={closeLivingModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addLivingPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                id="otherQuestions"
                                options={otherOptionsList}
                                getOptionLabel={(option) => option}
                                disableCloseOnSelect
                                onChange={handleOtherCategoriesChange}
                                defaultValue={otherOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Other Categories" placeholder="Category" onClick={refreshOther} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                )}
                            />
                            {showAddOther && <Other 
                                closeModal={closeOtherModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addOtherPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                className={classes.keysAuto}
                                // limitTags={2}
                                id="keys"
                                options={keyOptionsList}
                                getOptionLabel={(option) => option.question}
                                defaultValue={keyOptionsList}
                                onChange={handleKeyCategoriesChange}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Issued Keys" placeholder="Keys" onClick={refreshKeys} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.question}
                                    </li>
                                )}
                            />
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addKeyPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>
                            {showAddKey && <AddKeys 
                                closeModal={closeKeyModal}
                            />}

                            <Autocomplete
                                className={classes.Autocomplete}
                                multiple
                                // limitTags={2}
                                disableCloseOnSelect
                                id="surveyQuestions"
                                options={surveyOptionsList}
                                getOptionLabel={(option) => option.question}
                                onChange={handleSurveyCategoriesChange}
                                defaultValue={surveyOptionsList}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Survey Questions" placeholder="Survey" onClick={refreshSurveys} />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.question}
                                    </li>
                                )}
                            />
                            {showAddSurvey && <AddSurvey 
                                closeModal={closeSurveyModal}
                            />}
                            <IconButton 
                                color="primary" aria-label="add to shopping cart"
                                onClick={addSurveyPage}
                            >
                                <AddCircleIcon />
                                <p className={classes.add}>Add Option</p>
                            </IconButton>


                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default AddApartment;