import React, { useRef, useContext } from "react";
import {
  FormQuestionsContext,
  FormAnswersContext,
  ExpandedSectionContext,
} from "../../Store";
import SectionHeader from "../SectionHeader";
import ImageUploader from "../CommentsSection";
import Ratings from "../Ratings";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import FontAwesome from "react-fontawesome";
import styles from "./bedthreeform.module.css";

const BedThreeForm = React.memo(({ Name }) => {
  const formRef = useRef(null);
  const [expandedSection, setExpandedSection] = useContext(
    ExpandedSectionContext
  );

  const [formQuestions, setFormQuestions] = useContext(FormQuestionsContext);
  const [formAnswers, setFormAnswers] = useContext(FormAnswersContext);
  const array = formQuestions.bedthree;
  const onChange = React.useCallback(
    (e, name) => {
      const { value } = e.target;
      setFormAnswers((state) => ({
        ...state,
        [Name]: { ...state[Name], [name]: value },
      }));
    },
    [setFormAnswers]
  );
  const handleOpen = () => {
    return expandedSection === Name
      ? setExpandedSection("")
      : setExpandedSection(Name);
  };
  const onEntered = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const answeredQuestions = formAnswers.bedthree
    ? Object.keys(formAnswers.bedthree)
    : null;
  return (
    <div>
      <Button
        innerRef={formRef}
        className={styles["CollapseBtn"]}
        onClick={handleOpen}
        style={
          answeredQuestions &&
          answeredQuestions.length === formQuestions.bedthree.length
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#02BD43",
                },
                backgroundColor: "#02BD43",
                marginBottom: "1rem",
                width: "100%",
              }
            : answeredQuestions &&
              answeredQuestions.length !== formQuestions.bedthree.length
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#bd0202",
                },
                backgroundColor: "#bd0202",
                marginBottom: "1rem",
                width: "100%",
              }
            : {
                ":focus": {
                  backgroundColor: "#fafafa",
                },
                marginBottom: "1rem",
                width: "100%",
              }
        }
      >
        <p>BEDROOM #3 INSPECTION</p>
        <FontAwesome
          className="super-crazy-colors"
          name="angle-up"
          rotate={expandedSection === Name ? null : 180}
          size="lg"
          style={{
            marginTop: "5px",
            textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
          }}
        />
      </Button>
      <Collapse
        onEntered={onEntered}
        className={styles["Collapse"]}
        isOpen={expandedSection === Name}
      >
        <Card>
          <CardBody>
            <div>
              {array ? (
                <div>
                  <SectionHeader title="Bedroom #3 Inspection" name={Name} />
                  <div
                    className={styles["BedroomThreeFormWrapper"]}
                    id="bedroom-three-form"
                  >
                    {array.map((question, index) => {
                      const selected =
                        formAnswers[Name] && formAnswers[Name][question]
                          ? formAnswers[Name][question]
                          : "";
                      return (
                        <div className={styles["CheckboxWrapper"]} key={index}>
                          <h5>{question}</h5>
                          <Ratings
                            section={Name}
                            question={question}
                            onChange={onChange}
                            selected={selected}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {!answeredQuestions ? (
                    ""
                  ) : (
                    <Button
                      onClick={(e) => e.preventDefault()}
                      style={
                        !answeredQuestions ||
                        (answeredQuestions &&
                          answeredQuestions.length !==
                            formQuestions.bedthree.length)
                          ? {
                              backgroundColor: "#bd0202",
                              color: "white",
                              pointerEvents: "none",
                            }
                          : {
                              backgroundColor: "#02BD43",
                              color: "white",
                              pointerEvents: "none",
                            }
                      }
                    >
                      {!answeredQuestions ||
                      (answeredQuestions &&
                        answeredQuestions.length !==
                          formQuestions.bedthree.length)
                        ? "Incomplete"
                        : "Complete"}
                    </Button>
                  )}
                  <br />
                  <ImageUploader name="bedthree" title={"Bedroom #3"} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
});

export default BedThreeForm;
