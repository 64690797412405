import React, {useContext} from "react";
import Loader from "react-loader-spinner";
import { Form, Input } from "antd";
import styles from "../css/Index.module.css";
import MoveInForm from "./MoveInForm";
import { urlencoded } from "body-parser";
import { makeStyles } from '@material-ui/styles';
import Dashboard from '../components/Dashboard/Dashboard';
import Button from '@mui/material/Button';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  NavLink, 
  useRouteMatch
} from "react-router-dom";

const useStyles = makeStyles({
  form: {
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '10px',
    textAlign: 'center'
  },
  input: {
    '& input': {
      paddingLeft: '8px', 
      padding: '4px', 
      borderRadius: '3px',
      border: '1px solid gray',
      margin: '10px 6px 15px 0px'
    }
  }, 
  submit: {
    background: '#d0e5f2',
    border: '1px solid gray',
    borderRadius: '4px', 
    padding: '5px 10px',
    '&:hover': {
      background: '#b4d7ed',
    },
  }, 
  btnText: {
    color: 'black', 
    marginBotton: '2px', 
    marginTop: '15px', 
    fontSize: '20px',
    textDecoration: 'underline', 
    color: '#1976d2 !important'
  }, 
  pmDashboard: {
    position: 'absolute !important', 
    top: '25px',
    right: '50px', 
    padding: '5px !important',
    borderRadius: '15px !important', 
    backgroundColor: 'white !important', 
    boxShadow: '0px 0px 10px gray'
  }, 
  pmImage: {
    width: '150px'
  }
})

function Index({ Logo, Link, Email, Apartment, AccessCode, UseStripe, backgroundImage, backgroundColor, apartmentTitle, surveyQuestions, termsText, termsCheckboxLabel, reviewPageCode, keysFormQuestions, passwordColor, reviewPageMinimum, badRatingMessage, badRatingMessageColor }) {
  const [loading, setLoading] = React.useState(true);
  const [passwordCorrect, setPasswordCorrect] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const classes = useStyles();
  let { path, url } = useRouteMatch();

  const checkPassword = (e) => {
    e.preventDefault();
    if (password === AccessCode) {
      setPasswordCorrect(true);
    } else {
      setPasswordCorrect(false);
    }
  };
  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div style={{ backgroundImage : `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className={!passwordCorrect ? styles["IndexWrapper"] : ""}>
      {loading ? (
        <Loader
          className={styles["Loader"]}
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      ) : null}
      {!passwordCorrect && (
        <div>
        <NavLink 
          to={`${url}/dashboard/login`}
          className={classes.pmDashboard}
        >
          <img className={classes.pmImage} src="/PM_dashboard.jpg"></img>
        </NavLink>
        <Form 
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          className={classes.form}
          style={{backgroundColor: passwordColor ? passwordColor : 'white' }}
        >
          <Form.Item
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            rules={[{ required: true, message: 'Please input your password' }]}
          >
          <Input.Password className={classes.input} placeholder="Enter password.." />
          </Form.Item>
          <Form.Item {...tailLayout}>
          <Button className={classes.submit} type="primary" htmlType="submit" variant="contained" onClick={(e) => checkPassword(e)}>
            Submit
          </Button>
          </Form.Item>            
        </Form> 
        </div>
      )}
      {passwordCorrect && (
        <MoveInForm
          Logo={Logo}
          Link={Link}
          Email={Email}
          AccessCode={AccessCode}
          Apartment={Apartment}
          UseStripe={UseStripe}
          backgroundColor={backgroundColor}
          apartmentTitle={apartmentTitle}
          surveyQuestions={surveyQuestions}
          termsText={termsText}
          termsCheckboxLabel={termsCheckboxLabel}
          reviewPageCode={reviewPageCode}
          keysFormQuestions={keysFormQuestions}
          reviewPageMinimum={reviewPageMinimum}
          badRatingMessage={badRatingMessage}
          badRatingMessageColor={badRatingMessageColor}
        />
      )}
    </div>
  );
}

export default Index;


