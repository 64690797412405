const reviewPage = (details) => {
    let {
        link, 
        reviewText, 
        image
    } = details;

    return `<div style='background-color: #7c7c7c; height: 100vh; width: 100%;'>
    <div style="transform: translateY(${image ? '50%' : '100%'}); display: flex; flex-direction: column; align-items: center;">
        <div style='display: flex; justify-content: center; '> <img style='max-width: 30%;'
                src='https://dailycliparts.com/wp-content/uploads/2019/01/5-Star-Yellow-Golden-Ratings.png'
                alt='five star yellow review' /> </div>
        <h2
            style='color: white; padding: 20px; text-align: center; line-height: 1.2; font-weight: 400; font-size: 24px; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;'>
            ${reviewText}</h2> <a
            href='${link}'>
            <button
                style='font-size: 24px; background-color: #0cd50c; font-weight: 500; color: white; border: 3px solid white; cursor: pointer; padding: .375rem .75rem; line-height: 1.5; border-radius: 10px; transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;'
                onmouseover="this.style.background='#6b6d75'" onmouseout="this.style.background='#0cd50c'">Review Us
                Now</button> </a>
            ${image ? 
            `<img style="margin-top: 50px; width: 250px;" src="${image}">` : null}
    </div>
    </div>`
}

export default reviewPage;