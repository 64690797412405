
import { makeStyles } from '@material-ui/styles';
import axios from "axios";
import React, {useEffect, useState} from "react";
import EditUserForm from './components/EditUserForm';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
    dashboardBody: {
        height: '100vh', 
        width: '100%', 
        backgroundColor: '#f2f2f2', 
        textAlign: 'center', 
        position: 'absolute', 
        top: '0', 
        overflowY: 'hidden'
    }, 
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer', 
        zIndex: '5'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    },
    root: {
        '& .MuiPaper-root': {
            width: '400px !important', 
            alignItems: 'center', 
            borderRadius: '20px', 
            height: '500px', 
            justifyContent: 'center'
        }
    }
});

const EditUser = ({userId, closeModal}) => {
    const [loggedIn, SetLoggedIn] = useState(true);
    const [mobile, setMobile] = useState(false);
    const [click, setClick] = useState(false);
    const [apartments, setApartments] = useState([]);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);
    const [apartmentData, setApartmentData] = useState({
        name: '', 
        email: '', 
        apartment: '', 
        password: '', 
        _id: ''
    });
    const [received, setReceived] = useState(false);

    const classes = useStyles();

    const handleClick = () => setClick(!click);

    const showMobile = () => {
        if(window.innerWidth <= 960){
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    const retrieveApartments = async () => {
        if(apartments.length) return;
        try{
            const token = sessionStorage.getItem('userToken');
            const response = await axios({
                method: "get",
                url: `https://www.apartmentmii.com/apartments?secret_token=${token}`,
            });
            setApartments(response.data);
            console.log('apartments', response.data);
        }catch(error) {
            console.error('could not retrieve apartments', error);
        }
    }

    const retrieveEditApartment = async () => {
        if(received) return;
        try{
            const token = sessionStorage.getItem('userToken');
            const response = await axios({
                method: "get",
                url: `https://www.apartmentmii.com/users?secret_token=${token}&user_id=${userId}`,
            });
            const {
                name, 
                email, 
                apartment, 
                _id
            } = response.data[0];
            setApartmentData({
                ...apartmentData,
                name: name, 
                email: email, 
                apartment: apartment,
                _id: _id
            });
            setReceived(true);
        }catch(error) {
            console.error('could not retrieve apartments', error);
        }
    }

    const redirect = (status) => {
        if(status) {
            const loginSuccessNotif = () => toast.success("User Added Successfully!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            });
            loginSuccessNotif();
            setTimeout(() => {
                setRedirectToDashboard(true);
            }, 2000)
        }
    };

    const closeModalStatus = (close, userEdited) => {
        if(close && userEdited){
            closeModal(true, true);
        }else if(close){
            closeModal(true);
        }
    }

    window.addEventListener('resize', showMobile);

    useEffect(() => {
        showMobile();
        retrieveApartments();
        retrieveEditApartment();
    });

    return (
        <>
            <Dialog
                className={classes.root}
                fullWidth
                maxWidth="md"
                open={true}
            >
                <EditUserForm 
                    apartments={apartments}
                    redirect={redirect}
                    apartmentData={apartmentData}
                    mobile={mobile}
                    modalStatus={closeModalStatus}
                />
            </Dialog>
        </>
    )
}

export default EditUser;