import React, { useState } from "react";
import LivingRoomForm from "./components/LivingRoomForm";
import KitchenForm from "./components/KitchenForm";
import DiningRoomForm from "./components/DiningRoomForm";
import BathOneForm from "./components/BathOneForm";
import BathTwoForm from "./components/BathTwoForm";
import BedOneForm from "./components/BedOneForm";
import BedTwoForm from "./components/BedTwoForm";
import BedThreeForm from "./components/BedThreeForm";
import AppliancesForm from "./components/AppliancesForm";
import OtherForm from "./components/OtherForm";
import Dashboard from './components/Dashboard';

export const ClickedContext = React.createContext("");
export const KitchenFormContext = React.createContext({});
export const AppliancesFormContext = React.createContext({});
export const BathOneFormContext = React.createContext({});
export const BathTwoFormContext = React.createContext({});
export const BedOneFormContext = React.createContext({});
export const BedTwoFormContext = React.createContext({});
export const BedThreeFormContext = React.createContext({});
export const DiningRoomFormContext = React.createContext({});
export const LivingRoomFormContext = React.createContext({});
export const OtherAreasFormContext = React.createContext({});
export const SignatureFormContext = React.createContext([]);
export const TermsAgreementContext = React.createContext(false);
export const FormQuestionsContext = React.createContext({});
export const StarRatingsContext = React.createContext({});
export const ImageUploadContext = React.createContext({});
export const CommentsContext = React.createContext({});
export const ContactInfoContext = React.createContext({});
export const KeysFormContext = React.createContext({});
export const FormAnswersContext = React.createContext({});
export const LoadingContext = React.createContext({});
export const BelowThreeContext = React.createContext("");
export const SectionsContext = React.createContext([]);
export const ExpandedSectionContext = React.createContext("");
export const DashboardContext = React.createContext({});
export const ConditionRatings = React.createContext([]);

function Store({ children }) {
  const [expandedSection, setExpandedSection] = useState("");
  const [sections, setSections] = useState([
    {
      keyword: "kitchen",
      component: <KitchenForm key={"kitchen"} Name={"kitchen"} />,
    },
    {
      keyword: "appliances",
      component: <AppliancesForm key={"appliances"} Name={"appliances"} />,
    },
    {
      keyword: "dining",
      component: <DiningRoomForm key={"dining"} Name="dining" />,
    },
    {
      keyword: "living",
      component: <LivingRoomForm key={"living"} Name={"living"} />,
    },
    {
      keyword: "bathone",
      component: <BathOneForm key={"bathone"} Name={"bathone"} />,
    },
    {
      keyword: "bathtwo",
      component: <BathTwoForm key={"bathtwo"} Name={"bathtwo"} />,
    },
    {
      keyword: "bedone",
      component: <BedOneForm key={"bedone"} Name={"bedone"} />,
    },
    {
      keyword: "bedtwo",
      component: <BedTwoForm key={"bedtwo"} Name={"bedtwo"} />,
    },
    {
      keyword: "bedthree",
      component: <BedThreeForm key={"bedthree"} Name={"bedthree"} />,
    },

    {
      keyword: "other",
      component: <OtherForm key={"other"} Name={"other"} />,
    },
/*     {
      keyword: 'dashboard', 
      component: <Dashboard key={'dashboard'} Name={'dashboard'} />
    } */
  ]);
  const [belowThreeText, setBelowThreeText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [formQuestions, setFormQuestions] = useState({});
  const [formAnswers, setFormAnswers] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const [keysFormAnswers, setKeysFormAnswers] = useState({});
  const [comments, setComments] = useState({});
  const [files, setFiles] = useState({});
  const [starRatings, setStarRatings] = useState({});
  const [signatureFormImages, setSignatureFormImages] = useState([]);
  const [termsAgreementChecked, setTermsAgreementChecked] = useState(false);
  const [dashboardProps, setDashboardProps] = useState({});
  const [conditionRatings, setConditionRatings] = useState([]);

  return (
    <ExpandedSectionContext.Provider
      value={[expandedSection, setExpandedSection]}
    >
      <SectionsContext.Provider value={[sections, setSections]}>
        <BelowThreeContext.Provider value={[belowThreeText, setBelowThreeText]}>
          <LoadingContext.Provider value={[isLoading, setIsLoading]}>
            <FormAnswersContext.Provider value={[formAnswers, setFormAnswers]}>
              <KeysFormContext.Provider
                value={[keysFormAnswers, setKeysFormAnswers]}
              >
                <ContactInfoContext.Provider
                  value={[contactInfo, setContactInfo]}
                >
                  <CommentsContext.Provider value={[comments, setComments]}>
                    <ImageUploadContext.Provider value={[files, setFiles]}>
                      <StarRatingsContext.Provider
                        value={[starRatings, setStarRatings]}
                      >
                        <FormQuestionsContext.Provider
                          value={[formQuestions, setFormQuestions]}
                        >
                          <SignatureFormContext.Provider
                            value={[
                              signatureFormImages,
                              setSignatureFormImages,
                            ]}
                          >
                            <TermsAgreementContext.Provider
                              value={[termsAgreementChecked, setTermsAgreementChecked]}
                            >
                              <DashboardContext.Provider
                                value={[dashboardProps, setDashboardProps]}
                              >
                                <ConditionRatings.Provider
                                  value={[conditionRatings, setConditionRatings]}
                                >
                                  {children} 
                                </ConditionRatings.Provider>  
                              </DashboardContext.Provider>
                            </TermsAgreementContext.Provider>
                          </SignatureFormContext.Provider>
                        </FormQuestionsContext.Provider>
                      </StarRatingsContext.Provider>
                    </ImageUploadContext.Provider>
                  </CommentsContext.Provider>
                </ContactInfoContext.Provider>
              </KeysFormContext.Provider>
            </FormAnswersContext.Provider>
          </LoadingContext.Provider>
        </BelowThreeContext.Provider>
      </SectionsContext.Provider>
    </ExpandedSectionContext.Provider>
  );
}

export default Store;
