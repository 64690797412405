import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Toolbar from '@mui/material/Toolbar';


const useStyles = makeStyles({
    menu: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    close: {
        position: 'absolute', 
        top: '25px', 
        left: '25px', 
        fontSize: '40px', 
        cursor: 'pointer'
    }, 
    logout: {

    }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({ children, apartmentName, initialTab }) {
    const [value, setValue] = React.useState(initialTab);


    const classes = useStyles();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const clearSession = () => {
        sessionStorage.removeItem('userToken');
    }
  
    return (
        <>
        <Toolbar
          sx={{
            boxShadow: '0px 3px 5px 0px #888888',
            backgroundColor: 'rgb(25, 118, 210)', 
            zIndex: '100', 
            alignItems: 'center'
          }}
        />
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100vh', width: '15%', display: 'inline', position: 'fixed', left: '0', minWidth: '250px', transition: 'all 0.5s ease', zIndex: '10' }}
            >
                {children}
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider', height: '100%' }}
                >
                  <Tab label="Dashboard" {...a11yProps(1)} sx={{fontSize: '16px', marginTop: '20px', '&:hover': {textDecoration: 'none'}}} to={`/admin`} component={Link} />
                  <Tab label="Add Apartment" {...a11yProps(2)} sx={{fontSize: '16px', marginTop: '20px', '&:hover': {textDecoration: 'none'}}} to={`/admin/addapartment`} component={Link} />
                  <Tab label="Reset Password" {...a11yProps(3)} sx={{fontSize: '16px', marginTop: '20px', '&:hover': {textDecoration: 'none'}}} to={`/admin/resetpassword`} component={Link} />
                  <Tab label="Logout" {...a11yProps(0)} className={classes.logout} sx={{fontSize: '16px', position: 'absolute', width: '100%', bottom: '100px', '&:hover': {textDecoration: 'none'}}} to={`/admin/login`} component={Link} onClick={clearSession} />
                </Tabs>
            </Box>
        </>
    );
}