import React, { useContext, useState, useEffect, createContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useParams,
} from "react-router-dom";
import styles from "./App.module.css";
import DocumentTitle from "react-document-title";
import MoveInForm from "./pages/MoveInForm";
import Dashboard from './components/Dashboard/Dashboard';
import NoMatch from "./components/NoMatch";
import Store from "./Store";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Index from "./pages/Index";
import AdminLogin from './components/admin/login/AdminLogin';
import AdminPage from './components/admin/AdminPage/AdminPage';
import DashboardPage from './components/Dashboard/DashboardPage';
import PasswordReset from './components/Dashboard/passwordReset';
import AdminResetPassword from './components/admin/AdminPage/components/ResetPassword';
import AddApartment from './components/admin/AdminPage/components/AddApartment';

const stripePromise = loadStripe(
  "pk_test_51HfAz2KVSDknms1wBTrdVDginDs7EbZcnUa979cYKOrQi4HgozBQpWelRCC9UisSRraPZtoVcjbNPOPsd2o9v9v200pqnhGlM5"
);

function App() {
  // redirect to https so no ssl errors //
  if(window.location.href.indexOf('https') == -1){
    let remainingLink = window.location.href.split('http')[1];
    window.location.replace(`https${remainingLink}`);
  };
  
  const [logo, setLogo] = useState("");
  const [link, setLink] = useState("");
  const [email, setEmail] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [useStripe, setUseStripe] = useState(true);
  const [state, setState] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [apartmentTitle, setApartmentTitle] = useState("");
  const [surveyQuestions, setSurveyQuestions] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsText, setTermsText] = useState("");
  const [termsCheckboxLabel, setTermsCheckboxLabel] = useState("");
  const [reviewPageCode, setReviewPageCode] = useState("");
  const [keysFormQuestions, setKeysFormQuestions] = useState([]);
  const [propertyManagerPassword, setPropertyManagerPassword] = useState('');
  const [apartmentName, setApartmentName] = useState('');
  const [passwordColor, setPasswordColor] = useState('white');
  const [reviewPageMinimum, setReviewPageMinimum] = useState(5);
  const [badRatingMessage, setBadRatingMessage] = useState('');
  const [badRatingMessageColor, setBadRatingMessageColor] = useState('');

  //Title Case
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  //checking if client exists in DB
  const renderMoveIn = (routerProps) => {
    setApartmentName(routerProps.match.params.apartment.toLowerCase());

    axios
      .get(`api/${apartmentName}`)
      .then((res) => {
        const response = res.data[0];
        if(typeof response == 'object'){
          setLogo(response.logo);
          setLink(response.link);
          setEmail(response.email);
          setAccessCode(response.accessCode);
          if (response.useStripe === false) {
            setUseStripe(false);
          }
          setState(true);
          setBackgroundImage(response.backgroundImage);
          setBackgroundColor(response.backgroundColor);
          // document.body.style.background = backgroundColor;
          setApartmentTitle(response.apartmentTitle);
          setSurveyQuestions(JSON.stringify(response.surveyQuestions));
          setLoading(true);
          setTermsText(response.termsAgreement);
          setTermsCheckboxLabel(response.termsCheckboxLabel);
          setReviewPageCode(response.reviewPage);
          setKeysFormQuestions(JSON.stringify(response.keys));
          setPropertyManagerPassword(response.PropertyManagerPassword);
          setPasswordColor(response.residentPasswordColor);
          setReviewPageMinimum(response.reviewPageRatingMimimum);
          setBadRatingMessage(response.badRatingMessage);
          setBadRatingMessageColor(response.badRatingMessageColor);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const Apartment = apartmentName.toProperCase();
    return state ? (
      <Elements stripe={stripePromise}>
        <Index
          Logo={logo}
          Link={link}
          Email={email}
          AccessCode={accessCode}
          Apartment={Apartment}
          UseStripe={useStripe}
          backgroundImage={backgroundImage}
          backgroundColor={backgroundColor}
          apartmentTitle={apartmentTitle}
          surveyQuestions={surveyQuestions}
          termsText={termsText}
          termsCheckboxLabel={termsCheckboxLabel}
          reviewPageCode={reviewPageCode}
          keysFormQuestions={keysFormQuestions}
          passwordColor={passwordColor}
          reviewPageMinimum={reviewPageMinimum}
          badRatingMessage={badRatingMessage}
          badRatingMessageColor={badRatingMessageColor}
        />
      </Elements>
    ) : null;
  };
  const renderDashboard = (routerProps) => {
    setApartmentName(routerProps.match.params.apartment.toLowerCase());

    axios
      .get(`api/${apartmentName}`)
      .then((res) => {
        const response = res.data[0];
        if(typeof response == 'object'){
          setLogo(response.logo);
          setLink(response.link);
          setEmail(response.email);
          setAccessCode(response.accessCode);
          if (response.useStripe === false) {
            setUseStripe(false);
          }
          setState(true);
          setBackgroundImage(response.backgroundImage);
          setBackgroundColor(response.backgroundColor);
          // document.body.style.background = backgroundColor;
          setApartmentTitle(response.apartmentTitle);
          setSurveyQuestions(JSON.stringify(response.surveyQuestions));
          setLoading(true);
          setTermsText(response.termsAgreement);
          setTermsCheckboxLabel(response.termsCheckboxLabel);
          setReviewPageCode(response.reviewPage);
          setKeysFormQuestions(JSON.stringify(response.keys));
          setPropertyManagerPassword(response.PropertyManagerPassword);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const Apartment = apartmentName.toProperCase();
    return (
      <DashboardPage 
        Logo={logo}
        Link={link}
        Email={email}
        AccessCode={accessCode}
        Apartment={Apartment}
        UseStripe={useStripe}
        backgroundImage={backgroundImage}
        backgroundColor={backgroundColor}
        apartmentTitle={apartmentTitle}
        surveyQuestions={surveyQuestions}
        termsText={termsText}
        termsCheckboxLabel={termsCheckboxLabel}
        reviewPageCode={reviewPageCode}
        keysFormQuestions={keysFormQuestions}
        propertyManagerPassword={propertyManagerPassword}
      />
    )
  }

  const renderDashboardLogin = (routerProps) => {
    setApartmentName(routerProps.match.params.apartment.toLowerCase());

    axios
      .get(`api/${apartmentName}`)
      .then((res) => {
        const response = res.data[0];
        if(typeof response == 'object'){
          setLogo(response.logo);
          setLink(response.link);
          setEmail(response.email);
          setAccessCode(response.accessCode);
          if (response.useStripe === false) {
            setUseStripe(false);
          }
          setState(true);
          setBackgroundImage(response.backgroundImage);
          setBackgroundColor(response.backgroundColor);
          // document.body.style.background = backgroundColor;
          setApartmentTitle(response.apartmentTitle);
          setSurveyQuestions(JSON.stringify(response.surveyQuestions));
          setLoading(true);
          setTermsText(response.termsAgreement);
          setTermsCheckboxLabel(response.termsCheckboxLabel);
          setReviewPageCode(response.reviewPage);
          setKeysFormQuestions(JSON.stringify(response.keys));
          setPropertyManagerPassword(response.PropertyManagerPassword);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const Apartment = apartmentName.toProperCase();
    return (
      <Dashboard 
        Logo={logo}
        Link={link}
        Email={email}
        AccessCode={accessCode}
        Apartment={Apartment}
        UseStripe={useStripe}
        backgroundImage={backgroundImage}
        backgroundColor={backgroundColor}
        apartmentTitle={apartmentTitle}
        surveyQuestions={surveyQuestions}
        termsText={termsText}
        termsCheckboxLabel={termsCheckboxLabel}
        reviewPageCode={reviewPageCode}
        keysFormQuestions={keysFormQuestions}
        propertyManagerPassword={propertyManagerPassword}
      />
    )
  }
  useEffect(() => {}, [state]);
  return (
    <DocumentTitle title="Move-In Inspection">
      <Store>
        <Router>
          <div className={styles["App"]}>
            <Switch>
              <Route 
                path="/admin/login"
                component={AdminLogin}
              />
              <Route 
                path="/admin/resetpassword"
                component={AdminResetPassword}
              />
              <Route 
                path="/admin/addapartment"
                component={AddApartment}
              />
              <Route
                exact
                path="/admin"
                component={AdminPage}
              />
              <Route
                exact
                path="/:apartment"
                render={(routerProps) => renderMoveIn(routerProps)}
              />
              <Redirect exact path="/" to="/example" />
              <Route 
                path={`/:apartment/dashboard/login`}
                render={(routerProps) => renderDashboardLogin(routerProps)}
              />
              <Route 
                path={`/:apartment/dashboard/resetpassword`}
                component={PasswordReset}
              />
              <Route 
                path={`/:apartment/dashboard`}
                render={(routerProps) => renderDashboard(routerProps)}
              />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </Router>
      </Store>
    </DocumentTitle>
  );
}

export default App;
