import React, { useContext, useState, useEffect } from "react";
import { Button } from "reactstrap";
import Loader from "react-loader-spinner";
import styles from "../css/MoveInForm.module.css";
import {
  FormQuestionsContext,
  StarRatingsContext,
  CommentsContext,
  ImageUploadContext,
  ContactInfoContext,
  FormAnswersContext,
  KeysFormContext,
  SignatureFormContext,
  LoadingContext,
  BelowThreeContext,
  SectionsContext,
  TermsAgreementContext,
  ConditionRatings
} from "../Store";
import ContactForm from "../components/ContactForm";
import KeysForm from "../components/KeysForm";
import SignaturesForm from "../components/SignaturesForm";
import SurveyForm from "../components/SurveyForm";
import axios from "axios";
import Payment from "../components/Payment";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import TermsAgreement from '../components/TermsAgreement/TermsAgreement'
import moment from 'moment';


const headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append("Origin", "*");

const MoveInForm = React.memo(({ Logo, Link, Email, Apartment, UseStripe, backgroundColor, apartmentTitle, surveyQuestions, termsText, termsCheckboxLabel, reviewPageCode, keysFormQuestions, reviewPageMinimum, badRatingMessage, badRatingMessageColor }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentDeclined, setPaymentDeclined] = useState(false);
  const [passedCheck, setPassedCheck] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [complete, setComplete] = useState(true);
  const [apartment, setApartment] = useState("");
  //--------to be sent to server------------
  const [signatureFormImages, setSignatureFormImages] = useContext(
    SignatureFormContext
  );
  const [termsAgreementChecked, setTermsAgreementChecked] = useContext(TermsAgreementContext);
  const [formAnswers, setFormAnswers] = useContext(FormAnswersContext);
  const [starRatings, setStarRatings] = useContext(StarRatingsContext);
  const [comments, setComments] = useContext(CommentsContext);
  const [files, setFiles] = useContext(ImageUploadContext);
  const [contactInfo, setContactInfo] = useContext(ContactInfoContext);
  const [keysFormAnswers, setKeysFormAnswers] = useContext(KeysFormContext);
  const [belowThreeText, setBelowThreeText] = useContext(BelowThreeContext);
  //-----------end sent to server------------
  const [sections, setSections] = useContext(SectionsContext);
  const [isLoading, setIsLoading] = useContext(LoadingContext);
  const [formQuestions, setFormQuestions] = useContext(FormQuestionsContext);
  const [conditionRatings, setConditionRatings] = useContext(ConditionRatings);
  const surveyQs = JSON.parse(surveyQuestions);

  const reviewPageMinimumRating = reviewPageMinimum ? Number(reviewPageMinimum) : 5;
  const badReviewPageText = badRatingMessage ? badRatingMessage : 'Thank You! Your Move-in Inspection Form is now complete.';

  const getData = async () => {
    const apartmentName = Apartment.toLowerCase();
    const response = await axios.get(`api/${apartmentName}`);

    let filteredFormQuestions = {};
    const filterFormQs = ()=> {
      for (let [key, value] of Object.entries(response.data[0])) {
        if(key == 'appliances' || key == 'bathone' || key == 'bathtwo' || key == 'bedone' || key == 'bedtwo' || key == 'bedthree' || key == 'dining' || key == 'kitchen' || key == 'other' || key == 'living'){
          filteredFormQuestions[key] = value;
        }   
      }
    }
    filterFormQs();
    setFormQuestions(filteredFormQuestions);
    setApartment(response.data[0].apartment);
    if(response.data[0].conditionRatings){
      console.log(response.data[0].conditionRatings);
      setConditionRatings(response.data[0].conditionRatings);
    }
    setIsLoading(false);
    setPageLoading(false);
  };

  let newKeys = [];
  const keys = Object.keys(formQuestions).map((key, index) => {
    return formQuestions[key].length > 0 ? newKeys.push(key) : null;
  });
  let newKeys2 = [];
  const keys2 = Object.keys(formAnswers).map((key, index) => {
    return Object.keys(formAnswers[key]).length > 0 ? newKeys2.push(key) : null;
  })
  const idKey = newKeys.indexOf("_id");
  const emailKey = newKeys.indexOf("email");
  const apartmentKey = newKeys.indexOf("apartment");
  const logoKey = newKeys.indexOf("logo");
  const linkKey = newKeys.indexOf("link");
  const accessCodeKey = newKeys.indexOf("accessCode");
  const useStripeKey = newKeys.indexOf("useStripe");
  const removeValFrom =
    UseStripe === false
      ? [
          idKey,
          emailKey,
          apartmentKey,
          logoKey,
          linkKey,
          accessCodeKey,
          useStripeKey,
        ]
      : [idKey, emailKey, apartmentKey, logoKey, linkKey, accessCodeKey];
  newKeys = newKeys.filter(function (value, index) {
    return removeValFrom.indexOf(index) === -1;
  });
  const getAnswerKeys = formAnswers ? Object.keys(formAnswers) : null;
  const checkEachAnswer = () => {
    let array = newKeys
      ? newKeys.map((key, index) => {
          let objectKeys = formAnswers[key]
            ? Object.keys(formAnswers[key])
            : null;
          return formQuestions[key] &&
            formAnswers[key] &&
            objectKeys &&
            objectKeys.length === formQuestions[key].length
            ? true
            : false;
        })
      : null;
    let answer =
      array.length &&
      array.reduce(function (a, b) {
        return a === false || b === false ? false : true;
      });
    return answer === true ? setPassedCheck(true) : null;
  };
    // require all keys questions be anwswered regardless of length
    const allKeysQuestionsAnswered = Object.values(keysFormAnswers).length === JSON.parse(keysFormQuestions).length ? true : false;
  // require all survey questions to be answered
  const surveyKeys = starRatings ? Object.keys(starRatings).length === surveyQs.length : null;
  const passedChecker =
    !Email ||
    !contactInfo.fname ||
    !contactInfo.lname ||
    !contactInfo.email ||
    !contactInfo.phone ||
    !contactInfo.address ||
    !contactInfo.city ||
    !contactInfo.state ||
    !contactInfo.zip ||
    !signatureFormImages || 
    !passedCheck || 
    (termsText ? !termsAgreementChecked : '') ||
/*     !keysFormAnswers.fdkeys ||
    !keysFormAnswers.mbkeys ||
    !keysFormAnswers.cakeys ||
    !keysFormAnswers.gkeys  */
    !surveyKeys || 
    !allKeysQuestionsAnswered
      ? false
      : true;

  const createResident = async (keyFormAnswers) => {
    try{
      const response = await axios({
        method: "post",
        url: "api/resident",
        data: {
          areas: newKeys,
          formAnswers,
          signature: signatureFormImages[0],
          starRatings,
          surveyQs,
          keys: keyFormAnswers,
          address: `${contactInfo.address} ${contactInfo.unit ? contactInfo.unit : ''} ${contactInfo.city}, ${contactInfo.state}, ${contactInfo.zip}`, 
          name: `${contactInfo.fname} ${contactInfo.lname}`, 
          date: moment().format('l'), 
          apartment: Apartment.toLowerCase(), 
          images: files
        }
      });
    }catch(error){
      console.error('could not create resident', error);
    }
  }

  const keysArray = () => {
    let keysFormArray = [];
    const parsedKeysQuestions = JSON.parse(keysFormQuestions);
    parsedKeysQuestions.forEach(keyQuestion => {
      let keyObj = {
        id: keyQuestion["id"], 
        question: keyQuestion["question"], 
        answer: keysFormAnswers[keyQuestion["id"]]
      };
      keysFormArray.push(keyObj);
    });
    return keysFormArray;
  }

  const sendData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      return false;
    }
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    let error;
    let paymentMethod;
    if (UseStripe) {
      const data = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      error = data.error;
      paymentMethod = data.paymentMethod;
    }
    if (error && UseStripe) {
      setIsLoading(false);
      return false;
    } else {
      let stripeId;
      if (UseStripe) {
        const { id } = paymentMethod;
        stripeId = id;
      }
      if (!passedChecker) {
        await setComplete(false);
        return setIsLoading(false);
      } else {
        try {

          const keyFormAnswers = keysArray();

          await createResident(keyFormAnswers);

          const response = await axios({
            method: "post",
            url: "api/submitform",
            data: {
              useStripe: UseStripe,
              // paymentId: stripeId,
              apartment,
              signatures: signatureFormImages,
              logo: Logo,
              abovethreestars: Link,
              belowThreeText,
              email: Email,
              contact: contactInfo,
              appliances: formAnswers.appliances,
              bathone: formAnswers.bathone,
              bathtwo: formAnswers.bathtwo,
              bedone: formAnswers.bedone,
              bedtwo: formAnswers.bedtwo,
              bedthree: formAnswers.bedthree,
              dining: formAnswers.dining,
              kitchen: formAnswers.kitchen,
              living: formAnswers.living,
              other: formAnswers.other,
              ratings: starRatings,
              surveyQuestions: surveyQuestions,
              comments,
              files,
              keys: keysFormAnswers,
              termsText,
            },
          }).then((response) => {
            setIsLoading(false);
            setSuccess(true);
          })
        } catch (error) {
          console.log('error in send data', error);
          setIsLoading(false);
          setPaymentDeclined(true);
        }
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    checkEachAnswer();
  }, [formAnswers]);
  
  const lastSurveyQuestion = surveyQs[surveyQs.length - 1].id;
  document.body.style.background = backgroundColor;
  return (
    <div style={{ backgroundImage: '', background: backgroundColor}} >
      {(success && starRatings[lastSurveyQuestion] < reviewPageMinimumRating) ||
      (success && starRatings[lastSurveyQuestion] >= reviewPageMinimumRating && !Link) ? (
        <div className={styles["SuccessContainer"]}>
          {Logo ? (
            <img
              style={{ height: "75px", paddingRight: "10px" }}
              src={Logo}
              alt="Apartment"
            />
          ) : (
            ""
          )}
          <h1 style={{color: badRatingMessageColor ? badRatingMessageColor : 'black'}}>
            {badReviewPageText}
          </h1>
        </div>
      ) : success && starRatings[lastSurveyQuestion] >= reviewPageMinimumRating && Link ? (
          <React.Fragment>
            <div 
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              dangerouslySetInnerHTML={{__html: reviewPageCode}}
            >
            </div>
          </React.Fragment>
      ) : (
        <div className={styles["ContactForm"]}>
          <form>
            {isLoading ? (
              <Loader
                className={styles["Loader"]}
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            ) : null}
            {!pageLoading ? (
              <div>
                <div className={styles["FormHeader"]}>
                  {Logo && Logo.length ? (
                    <img
                      style={{ height: "75px", paddingRight: "10px" }}
                      src={Logo}
                      alt="Apartment"
                    />
                  ) : (
                    ""
                  )}

                  <h1>{apartmentTitle} Move-in Form</h1>
                </div>
                <ContactForm Name="contact" />
                {sections.map((component, index) => {
                  return newKeys.includes(component.keyword)
                    ? component.component
                    : null;
                })}
                <KeysForm 
                Name="keys"
                keysFormQuestions={keysFormQuestions}
                />
                <SignaturesForm Name="signatures" />
                <SurveyForm 
                Name="survey" 
                surveyQuestions={surveyQuestions}
                reviewPageMinimum={reviewPageMinimum}
                />
                {termsText ? (
                  <TermsAgreement 
                  Name="termsagreement"
                  termsText={termsText}
                  termsCheckboxLabel={termsCheckboxLabel ? termsCheckboxLabel : 'I acknowledge that I have read, and do hereby accept the terms and policies of this move-in inspection form.'}
                  />
                ) : ('')}
                {UseStripe !== false && (
                  <Payment Name="payment">
                    <div style={{ marginBottom: "30px" }}>
                      <strong className="margin-bottom">Amount: $5</strong>
                    </div>
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            color: "#424770",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                    <p style={{ marginTop: "30px" }} className="margin-top">
                      This charge will appear on your bank statement as MOVE-IN
                      INSPECTION
                    </p>
                  </Payment>
                )}
                <div className={styles["SubmitBox"]}>
                  <div className={styles["ErrorMessage"]}>
                    {!complete
                      ? "*Please Complete All the Required Fields*"
                      : null}
                  </div>
                  <div className={styles["ErrorMessage"]}>
                    {paymentDeclined
                      ? "Payment did not go through. Please try entering new credentials or another payment method."
                      : null}
                  </div>
                  <div className={styles["SubmitButtonContainer"]}>
                    <Button
                      disabled={!passedChecker || isLoading || !stripe}
                      onClick={sendData}
                      className={styles["SubmitBtn"]}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      )}
    </div>
  );
});
export default MoveInForm;
