import React, { useContext } from "react";
import { FormGroup, CustomInput } from "reactstrap";
import { ConditionRatings } from '../../Store';

const Ratings = React.memo(({ selected, section, question, onChange }) => {
  const [conditionRatings, setConditionRatings] = useContext(ConditionRatings);
  const conditions = conditionRatings.length ? conditionRatings : ['Good', 'Fair', 'Poor'];
  return (
    <div>
      <FormGroup>
        <div>
          {conditions && conditions.map((rating, index) => 
            <CustomInput
              key={index}
              checked={selected === rating}
              onChange={(e) => onChange(e, question)}
              type="radio"
              id={`${section}_${question}_${rating}`}
              value={rating}
              label={rating}
            />
          )}
          {/* <CustomInput
            checked={selected === "Good"}
            onChange={(e) => onChange(e, question)}
            type="radio"
            id={`${section}_${question}_Good`}
            value="Good"
            label="Good"
          />
          <CustomInput
            checked={selected === "Fair"}
            onChange={(e) => onChange(e, question)}
            type="radio"
            id={`${section}_${question}_Fair`}
            value="Fair"
            label="Fair"
          />
          <CustomInput
            checked={selected === "Poor"}
            onChange={(e) => onChange(e, question)}
            type="radio"
            id={`${section}_${question}_Poor`}
            value="Poor"
            label="Poor"
          /> */}
          <CustomInput
            checked={selected === "N/A"}
            onChange={(e) => onChange(e, question)}
            type="radio"
            id={`${section}_${question}_NA`}
            value="N/A"
            label="N/A"
          />
        </div>
      </FormGroup>
    </div>
  );
});

export default Ratings;
