import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/styles';
import './pdf.css';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import axios from 'axios';

const useStyles = makeStyles({
    images: {
        display: 'inline-block', 
        width: '250px',
        marginRight: '15px'
    }, 
    imagesDiv: {
        width: '100%', 
        textAlign: 'left', 
        paddingLeft: '50px', 
        paddingRight: '50px'
    }, 
    imageTitle: {
        fontSize: '24px', 
        textAlign: 'center', 
        margin: '20px'
    }
});

const PdfPage = ({formAnswers, areas, signature, starRatings, surveyQs, keys, name, address, date, apartment, images, finishedLoading, retrievingImages, resetTable}) => {
    const classes = useStyles();
    const [areaHeaders, setAreaHeaders] = useState(areas);
    const [bathTwo, setBathTwo] = useState(areas.includes('bathtwo'));
    const [bedTwo, setBedTwo] = useState(areas.includes('bedtwo'));
    const [bedThree, setBedThree] = useState(areas.includes('bedthree'));
    const [imageData, setImageData] = useState({})

    surveyQs.sort((a, b) => {
        return Number(a.id) - Number(b.id);
    });

    const wait = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, 2000);
        })
    }
    let currentPageAdd = 5;
    const addImagesPage = () => {
        const currentImagesPage = document.querySelector(`.page${currentPageAdd.toString()}`);
        const imagesPageHeight = currentImagesPage.offsetHeight;
        if(imagesPageHeight <= 1200){
            if(currentPageAdd == 7) return;
            currentPageAdd++;
            addImagesPage();
            return;
        }

        const childrenDivs = currentImagesPage.querySelector('.images').children;
        const lastChild = childrenDivs[childrenDivs.length - 1];
        const nextPage = document.querySelector(`.page${(currentPageAdd + 1).toString()} .images`);
        nextPage.appendChild(lastChild);
        addImagesPage();
    }

    const getImages = async () => {
        if(!images) return;
        retrievingImages();
        const imagesData = {};
        const imageFields = Object.keys(images);
        for(let i = 0; i < imageFields.length; i++){
            let fieldName = imageFields[i];
            for(let j = 0; j < images[fieldName].length; j++){
                const filename = images[fieldName][j].filename;
                const imageNameArray = filename.split('.');
                const extension = imageNameArray[imageNameArray.length - 1];
                const response = await axios({
                    method: "get",
                    url: `https://www.apartmentmii.com/uploadImage/${filename}`,
                });
            
                if(imagesData[fieldName]){
                    imagesData[fieldName].push(response.data);
                }else {
                    imagesData[fieldName] = [response.data];
                }
            }
        }
        setImageData(imagesData);
        await wait();
        addImagesPage();
    }    

    const createPdf = async () => {
        const pdfBody = document.querySelector('.pdfBody');
        const page3Height = document.querySelector('.page3').offsetHeight;
        const page4Height = document.querySelector('.page4').offsetHeight;
        if(page3Height + page4Height < 1100){
            document.querySelector('.page3').appendChild(document.querySelector('.page4'))
        }

        const clearEmptyFields = () => {
            const moveInFields = document.querySelectorAll('.moveInCondition');
            moveInFields.forEach((field) => {
                const text = field.innerText;
                if(!text.length){
                    field.parentElement.remove();
                }
            });
        }
        clearEmptyFields();

        try{
            const doc = new jsPDF('p', 'px', 'a4');
            await html2canvas(document.querySelector('.page1'), { allowTaint: true, logging: true }).then(function(canvas) {
                document.body.appendChild(canvas);
                canvas.style.width = '455px';
                canvas.style.height = 'auto'; 
                let canvasWidth = canvas.offsetWidth;
                let canvasHeight = canvas.offsetHeight;
                canvas.id = 'canvasPage1';
                let imgData = canvas.toDataURL('image/png');
                doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                canvas.remove();
            });
            doc.addPage();
            await html2canvas(document.querySelector('.page2'), { allowTaint: true, logging: true }).then(function(canvas) {
                document.body.appendChild(canvas);
                canvas.style.width = '455px';
                canvas.style.height = 'auto';
                let canvasWidth = canvas.offsetWidth;
                let canvasHeight = canvas.offsetHeight;
                canvas.id = 'canvasPage1';
                let imgData = canvas.toDataURL('image/png');
                doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                canvas.remove();
            });
            doc.addPage();
            await html2canvas(document.querySelector('.page3'), { allowTaint: true, logging: true }).then(function(canvas) {
                document.body.appendChild(canvas);
                canvas.style.width = '455px';
                canvas.style.height = 'auto';
                let canvasWidth = canvas.offsetWidth;
                let canvasHeight = canvas.offsetHeight;
                canvas.id = 'canvasPage1';
                let imgData = canvas.toDataURL('image/png');
                doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                canvas.remove();
            });
            if(document.querySelector('.page4').parentElement.classList.value != 'page3'){
                doc.addPage();
                await html2canvas(document.querySelector('.page4'), { allowTaint: true, logging: true }).then(function(canvas) {
                    document.body.appendChild(canvas);
                    canvas.style.width = '455px';
                    canvas.style.height = 'auto';
                    let canvasWidth = canvas.offsetWidth;
                    let canvasHeight = canvas.offsetHeight;
                    canvas.id = 'canvasPage1';
                    let imgData = canvas.toDataURL('image/png');
                    doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                    canvas.remove();
                });
            }
            const checkIfImagesExist = () => {
                let imagesExist = false;
                const imageCategories = Object.keys(images);
                imageCategories.forEach(category => {
                    if(images[category].length){
                        imagesExist = true;
                    }
                });
                return imagesExist;
            }
            const imagesExist = checkIfImagesExist();
            if(imagesExist){
                doc.addPage();
                await html2canvas(document.querySelector('.page5'), { allowTaint: true, logging: true }).then(function(canvas) {
                    document.body.appendChild(canvas);
                    canvas.style.width = '455px';
                    canvas.style.height = 'auto';
                    let canvasWidth = canvas.offsetWidth;
                    let canvasHeight = canvas.offsetHeight;
                    canvas.id = 'canvasPage1';
                    let imgData = canvas.toDataURL('image/png');
                    doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                    canvas.remove();
                });
            }
            const page6Children = document.querySelector('.page6 .images').children.length;
            const page7Children = document.querySelector('.page7 .images').children.length;
            const page8Children = document.querySelector('.page8 .images').children.length;
            if(page6Children){
                doc.addPage();
                await html2canvas(document.querySelector('.page6'), { allowTaint: true, logging: true }).then(function(canvas) {
                    document.body.appendChild(canvas);
                    canvas.style.width = '455px';
                    canvas.style.height = 'auto';
                    let canvasWidth = canvas.offsetWidth;
                    let canvasHeight = canvas.offsetHeight;
                    canvas.id = 'canvasPage1';
                    let imgData = canvas.toDataURL('image/png');
                    doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                    canvas.remove();
                });
            }
            if(page7Children){
                doc.addPage();
                await html2canvas(document.querySelector('.page7'), { allowTaint: true, logging: true }).then(function(canvas) {
                    document.body.appendChild(canvas);
                    canvas.style.width = '455px';
                    canvas.style.height = 'auto';
                    let canvasWidth = canvas.offsetWidth;
                    let canvasHeight = canvas.offsetHeight;
                    canvas.id = 'canvasPage1';
                    let imgData = canvas.toDataURL('image/png');
                    doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                    canvas.remove();
                });
            }
            if(page8Children){
                doc.addPage();
                await html2canvas(document.querySelector('.page8'), { allowTaint: true, logging: true }).then(function(canvas) {
                    document.body.appendChild(canvas);
                    canvas.style.width = '455px';
                    canvas.style.height = 'auto';
                    let canvasWidth = canvas.offsetWidth;
                    let canvasHeight = canvas.offsetHeight;
                    canvas.id = 'canvasPage1';
                    let imgData = canvas.toDataURL('image/png');
                    doc.addImage(imgData, 'png', 0, 25, canvasWidth, canvasHeight);
                    canvas.remove();
                });
            }
            doc.save(`${name} Move In Form.pdf`);
            pdfBody.remove();
            finishedLoading();
            resetTable();
        }catch(error){
            console.error('could not save pdf', error);
            resetTable();
        }
    };

    useEffect(async () => {
        await getImages();
        createPdf();
    }, []);

    return (
        <div className="pdfBody" onClick={createPdf}>
            <div className="page1">
                <h4>MOVE-IN / MOVE OUT INSPECTION CHECKLIST</h4>
                <div className="contactInfo">
                    <p>Resident Name: <span>{name}</span></p>
                    <p>Apartment Address: <span>{address}</span></p>
                    <p>Date: <span>{date}</span></p>
                </div>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Area / Item</th>
                            <th>Condition @ Move-In</th>
                            <th>Condition @ Exit</th>
                            <th>Est. Repair Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tableSection" colSpan="4">KITCHEN</td>
                        </tr>
                        {formAnswers.kitchen && Object.keys(formAnswers.kitchen).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.kitchen[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}

                        <tr>
                            <td className="tableSection" colSpan="4">APPLIANCES</td>
                        </tr>
                        {formAnswers.appliances && Object.keys(formAnswers.appliances).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.appliances[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}

                        <tr>
                            <td className="tableSection" colSpan="4">DINING ROOM</td>
                        </tr>
                        {formAnswers.dining && Object.keys(formAnswers.dining).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.dining[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}
                    </tbody>
                </table>
            </div>
            
            <div className="page2">
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Area / Item</th>
                            <th>Condition @ Move-In</th>
                            <th>Condition @ Exit</th>
                            <th>Est. Repair Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tableSection" colSpan="4">LIVING ROOM</td>
                        </tr>
                        {formAnswers.living && Object.keys(formAnswers.living).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.living[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}

                        <tr>
                            <td className="tableSection" colSpan="4">BATHROOM ONE</td>
                        </tr>
                        {formAnswers.bathone && Object.keys(formAnswers.bathone).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.bathone[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}

                        {bathTwo ? 
                        <>
                        <tr>
                            <td className="tableSection" colSpan="4">BATHROOM TWO</td>
                        </tr>
                        {formAnswers.bathtwo && Object.keys(formAnswers.bathtwo).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.bathtwo[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}
                        </> : 
                        <>
                        <tr>
                            <td className="tableSection" colSpan="4">BEDROOM ONE</td>
                        </tr>
                        {formAnswers.bedone && Object.keys(formAnswers.bedone).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.bedone[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}

                        {bedTwo ? 
                        <>
                        <tr>
                            <td className="tableSection" colSpan="4">BEDROOM TWO</td>
                        </tr>
                        {formAnswers.bedtwo && Object.keys(formAnswers.bedtwo).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.bedtwo[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}
                        </> : null}
                        </>}

                        </tbody>
                </table>
            </div>
            
            <div className="page3">
            <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Area / Item</th>
                            <th>Condition @ Move-In</th>
                            <th>Condition @ Exit</th>
                            <th>Est. Repair Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bathTwo ? 
                        <>
                        <tr>
                            <td className="tableSection" colSpan="4">BEDROOM ONE</td>
                        </tr>
                        {formAnswers.bedone && Object.keys(formAnswers.bedone).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.bedone[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}

                        {bedTwo ? 
                        <>
                        <tr>
                            <td className="tableSection" colSpan="4">BEDROOM TWO</td>
                        </tr>
                        {formAnswers.bedtwo && Object.keys(formAnswers.bedtwo).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.bedtwo[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}
                        </> : null} 
                        </> : null}
                        {bedThree ? 
                        <>
                        <tr>
                            <td className="tableSection" colSpan="4">BEDROOM THREE</td>
                        </tr>
                        {formAnswers.bedthree && Object.keys(formAnswers.bedthree).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.bedthree[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}
                        </> : null}

                        <tr>
                            <td className="tableSection" colSpan="4">OTHER AREAS</td>
                        </tr>
                        {formAnswers.other && Object.keys(formAnswers.other).map(area => 
                            <tr>
                                <td>{area}</td>
                                <td className="moveInCondition">{formAnswers.other[area]}</td>
                                <td></td>
                                <td></td>
                            </tr>    
                        )}
                    </tbody>
                </table>
            </div>

            <div className="page4">
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Key Type</th>
                            <th>Quantity / Extra</th>
                        </tr>
                    </thead>
                    <tbody>
                        {keys && keys.map(key => 
                            <tr>
                                <td>{key.question}</td>
                                <td className="moveInCondition">{key.answer}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th>Survey Question</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {surveyQs.map((question) => 
                            <React.Fragment>
                                <tr>
                                    <td>{question.question}</td>
                                    <td className="moveInCondition">{starRatings[Number(question.id)]}</td>
                                </tr>
                            </React.Fragment>
                           
                        )}
                    </tbody>
                </table>
                <div className="signature">
                    <h4>Signature Form</h4>
                    <img className="signatureImg" src={signature}></img>
                </div>
                
            </div>

            <div className="page5">
                <div className="images">
                    {Object.keys(imageData).map(field => 
                        <div className={classes.imagesDiv} key={field} id={field}>
                            <h5 className={classes.imageTitle}>{field}</h5>
                            {imageData[field].map(image => 
                                <img className={classes.images} src={image.imgurl}></img>    
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="page6">
                <div className="images">

                </div>
            </div>

            <div className="page7">
                <div className="images">

                </div>
            </div>

            <div className="page8">
                <div className="images">

                </div>
            </div>
        </div>
    )
};

export default PdfPage;

