import React, { useContext, useEffect, useState, useRef } from "react";
import {
  StarRatingsContext,
  BelowThreeContext,
  ExpandedSectionContext,
} from "../../Store";
import StarRatings from "react-star-ratings";
import styles from "./surveyform.module.css";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import FontAwesome from "react-fontawesome";

const SurveyForm = React.memo(({ Name, surveyQuestions, reviewPageMinimum }) => {
  const reviewPageMinimumRating = reviewPageMinimum ? Number(reviewPageMinimum) : 5;
  surveyQuestions = JSON.parse(surveyQuestions);
  const formRef = useRef(null);
  const [expandedSection, setExpandedSection] = useContext(
    ExpandedSectionContext
  );
  const [belowThree, setBelowThree] = useState(false);
  const [belowThreeText, setBelowThreeText] = useContext(BelowThreeContext);
  const [starRatings, setStarRatings] = useContext(StarRatingsContext);
  const checkBelowThreeStars = () => {
    const lastSurveyQuestion = surveyQuestions[surveyQuestions.length - 1].id;
    if (starRatings[lastSurveyQuestion] && starRatings[lastSurveyQuestion] < reviewPageMinimumRating) {
      return setBelowThree(true);
    } else {
      setBelowThree(false);
      return setBelowThreeText("");
    }
  };
  const handleChange = (newRating, name) => {
    setStarRatings((state) => ({ ...state, [name]: newRating }));
  };
  const handleTextChange = (e) => {
    setBelowThreeText(e.target.value);
  };
  const handleOpen = () => {
    expandedSection === Name
      ? setExpandedSection("")
      : setExpandedSection(Name);
  };
  const onEntered = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  const answeredQuestions = starRatings ? Object.keys(starRatings) : null;
  const belowThreeCheck = belowThree ? (belowThreeText.length ? true : false) : true;
  const expectedLength = Object.keys(surveyQuestions).length;

  surveyQuestions.sort(function(a, b) {
    return Number(a.id) - Number(b.id);
  });
  useEffect(() => {
    checkBelowThreeStars();
  });
  return (
    <div>
      <Button
        innerRef={formRef}
        className={styles["CollapseBtn"]}
        onClick={handleOpen}
        style={
          answeredQuestions && answeredQuestions.length === expectedLength
            ? {
                color: "white",
                ":focus": {
                  backgroundColor: "#02BD43",
                },
                backgroundColor: "#02BD43",
                marginBottom: "1rem",
                width: "100%",
              }
            : {
                ":focus": {
                  backgroundColor: "#fafafa",
                },
                marginBottom: "1rem",
                width: "100%",
              }
        }
      >
        <p>SURVEY FORM</p>
        <FontAwesome
          className="super-crazy-colors"
          name="angle-up"
          rotate={expandedSection === Name ? null : 180}
          size="lg"
          style={{
            marginTop: "5px",
            textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
          }}
        />
      </Button>
      <Collapse
        onEntered={onEntered}
        className={styles["Collapse"]}
        isOpen={expandedSection === Name}
      >
        <Card>
          <CardBody>
            <h6>
              We're happy to welcome you to our community! Please take a few
              moments to share your thoughts. On a scale of 1-5, please rate
              your overall satisfaction (with 5 being very satisfied and 1 being
              very unsatisfied):
            </h6>
            {surveyQuestions.map(question => 
              <React.Fragment>
                <p className={styles["Question"]}>
                  {question.question}
                </p>
                <StarRatings
                  rating={starRatings[question.id]}
                  starRatedColor="blue"
                  starDimension="30px"
                  changeRating={handleChange}
                  numberOfStars={5}
                  name={question.id}
                />
              </React.Fragment>
              )}
            <div>
              {belowThree ? (
                <div>
                  <h6 className={styles["ImproveArea"]}>
                    How could your experience be better?
                  </h6>
                  <textarea
                    value={belowThreeText}
                    onChange={handleTextChange}
                    className={styles["BelowThreeRating"]}
                  />
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
});

export default SurveyForm;


